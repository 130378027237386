
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAJ8kKpjGfF5enlbp4LIGC74h5D-lOCpdw",
  authDomain: "typecelerate-35446.firebaseapp.com",
  projectId: "typecelerate-35446",
  storageBucket: "typecelerate-35446.appspot.com",
  messagingSenderId: "746867931829",
  appId: "1:746867931829:web:51fe97356be1d2b8059cfc",
  measurementId: "G-W2HRH1QRHF"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

export { firebaseApp, auth };