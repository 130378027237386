import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Caret = ({ position, isLoading, isFocused, shouldBlink=false }) => {
  const theme = useTheme();
  const caretRef = useRef(null);

  useEffect(() => {
    if (isLoading || !isFocused || !position) return;

    const updateCaretPosition = () => {
      if (caretRef.current) {
        // Adjust the position slightly up and left
        caretRef.current.style.transform = `translate(${position.left - 1}px, ${position.top - 1}px)`;
        caretRef.current.style.width = `${position.width}px`;
        caretRef.current.style.height = `${position.height - 2}px`; // Increase height slightly
        caretRef.current.style.opacity = '1';
      }
    };

    requestAnimationFrame(updateCaretPosition);
  }, [position, isLoading, isFocused]);

  return (
    <Box
      ref={caretRef}
      data-caret
      sx={{
        position: 'absolute',
        backgroundColor: theme.palette.action.disabledBackground,
        // backgroundColor: 'white',
        zIndex: 1,
        transition: 'all 0.1s ease-out',
        opacity: 0,
        borderRadius: '2px',
        left: 0,
        top: 0,
        pointerEvents: 'none',
        animation: shouldBlink ? 'blink 1.0s infinite' : 'none',
        '@keyframes blink': {
          '0%': { opacity: 0 },
          '50%': { opacity: 1 },
          '100%': { opacity: 0 },
        },
      }}
    />
  );
};

export { Caret };