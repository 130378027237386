const defaultSlowPatternTemplates = ["Trigrams", "Bigrams"];
const defaultInaccuratePatternTemplates = ["Bigrams"];
const defaultDesiredWeaknessHistory = 6;
const defaultDesiredWordsHistory = 6;
const defaultDesiredRefreshInterval = 10;
const defaultInaccuratePatternsCount = 10;
const defaultSlowPatternsCount = 10;
const defaultDesiredRandomness = 5;
const defaultRandomDuration = 2;
const defaultLanguage = 'English';
const defaultWordList = '10k';
const defaultTestLength = 'Medium';
const defaultLetterLimit = 25;


const testLengths = ['Burst', 'Short', 'Medium', 'Long'];
const testDurations = {
  Burst: 9,
  Short: 18,
  Medium: 39,
  Long: 69,
};

const languageCodes = {
  English: 'en',
  Spanish: 'es',
  French: 'fr',
  Italian: 'it',
  German: 'de',
  Arabic: 'ar',
  Portuguese: 'pt',
  Russian: 'ru',
  Hebrew: 'he',
  Chinese: 'zh',
};

const wordListSizes = ['500', '1k', '5k', '10k'];
const languages = ['Arabic', 'English', 'Hebrew', 'Italian', 'Portuguese', 'Russian', 'Spanish'/*, 'Chinese'*/];

export {
  defaultSlowPatternTemplates, defaultInaccuratePatternTemplates,
  defaultDesiredWeaknessHistory, defaultDesiredRefreshInterval,
  defaultInaccuratePatternsCount, defaultSlowPatternsCount,
  defaultDesiredRandomness, defaultRandomDuration, testDurations,
  languageCodes, wordListSizes, testLengths, 
  defaultLanguage, defaultWordList, defaultTestLength, 
  languages, defaultDesiredWordsHistory, defaultLetterLimit
};