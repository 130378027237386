import React, { useState, useContext, useEffect } from 'react';
import {
  Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
  IconButton, AppBar, Toolbar, Typography, Button, Dialog, DialogTitle,
  DialogContent, DialogActions, Stack, CircularProgress, Box
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { SvgIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Import your existing icons and components
import InfoIcon from '@mui/icons-material/Info';
import EmailIcon from '@mui/icons-material/Email';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { AuthButton } from './AuthButtonFirebase';
import { FaRoad } from "react-icons/fa";
import { IoMdDocument } from "react-icons/io";
import { RiPresentationFill } from "react-icons/ri";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { useTour } from '@reactour/tour'

import { PaletteSelector } from './PaletteSelector';
import FontSelector from './FontSelector';
import AvatarSelector from './AvatarSelector';
import RoadMap from './RoadMap';
import PayPalSubscriptionButton from './PayPalSubscriptionButton';
import { ContactDialog } from './Header';
import MascotIcon from './MascotIcon';

import { AppContext } from '../contexts/AppContext.jsx';

const HomeButtonWithLogo = ({ onClick }) => (
  <Button onClick={onClick} color="inherit" sx={{ textAlign: 'left', p: 0 }}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <MascotIcon />
      <Box sx={{ ml: 1 }}>
        <Typography variant="h4" component="div" sx={{ lineHeight: 1 }}>
          Typecelerate
        </Typography>
        <Typography 
          variant="subtitle2" 
          sx={{ 
            fontSize: '0.9rem', 
            fontStyle: 'italic',
            color: 'text.secondary',
            lineHeight: 1,
            mt: 0.5
          }}
        >
          The missing piece in your typing practice routine
        </Typography>
      </Box>
    </Box>
  </Button>
);


const SidebarMenu = () => {
  // const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [roadMapOpen, setRoadmapOpen] = useState(false);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const { currUser, isUserLoading, setIsFocused, isSidebarOpen, setIsSidebarOpen } = useContext(AppContext);
  const { setIsOpen } = useTour();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const firstVisit = localStorage.getItem('firstVisit');
    if (!firstVisit) {
      setIsOpen(true);
      localStorage.setItem('firstVisit', 'true');
    }
  }, [setIsOpen]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsSidebarOpen(open);
  };

  const navigateToRoot = () => {
    navigate('/');
    setIsFocused(true);
    setIsSidebarOpen(false);
  };

  const handleClickOpenToSRoute = () => {
    navigate('/privacy-policy-and-terms-of-service');
    setIsSidebarOpen(false);
  };

  const navigateToAppInfo = () => {
    navigate('/info');
    setIsSidebarOpen(false);
  };

  const handleOpenRoadmap = () => {
    setRoadmapOpen(true);
    setIsSidebarOpen(false);
  };

  const handleCloseRoadmap = () => {
    setRoadmapOpen(false);
  };

  const handleOpenContact = () => {
    setContactOpen(true);
    setIsSidebarOpen(false);
  };

  const handleCloseContact = () => {
    setContactOpen(false);
  };

  const handleOpenPaymentDialog = () => {
    setPaymentDialogOpen(true);
    setIsSidebarOpen(false);
  };

  const handleClosePaymentDialog = () => {
    setPaymentDialogOpen(false);
  };

  const navigateToContributors = () => {
    navigate('/contributors');
    setIsSidebarOpen(false);
  }

  const menuItems = [
    // { text: 'Typing Practice', icon: <KeyboardIcon />, onClick: navigateToRoot },
    { text: 'Onboarding Tour', icon: <RiPresentationFill />, onClick: () => setIsOpen(true), dataTour: "onboarding" },
    { text: 'Information', icon: <InfoIcon />, onClick: navigateToAppInfo },
    { text: 'Subscribe', icon: <AddShoppingCartIcon />, onClick: handleOpenPaymentDialog },
    { text: 'Contact', icon: <EmailIcon />, onClick: handleOpenContact, dataTour: "contact" },
    { text: 'Roadmap', icon: <FaRoad />, onClick: handleOpenRoadmap },
    { text: 'Terms of Service', icon: <IoMdDocument />, onClick: handleClickOpenToSRoute },
    { text: 'Contributors', icon: <IoMdDocument />, onClick: navigateToContributors },
  ];

  const list = () => (
    <List>
      {menuItems.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemButton onClick={item.onClick} data-tour={item.dataTour}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      <AppBar position="static" enableColorOnDark={true} color='primary'
        sx={{
          backgroundColor: theme.palette.primary.main,
          mb: '1em',
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon fontSize="large"/>
            </IconButton>
            <HomeButtonWithLogo onClick={navigateToRoot} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PaletteSelector />
            <AvatarSelector />
            <FontSelector />
            <AuthButton />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={isSidebarOpen}
        onClose={toggleDrawer(false)}
        // hideBackdrop={true}
        variant="temporary"
      >
        {list()}
      </Drawer>

      {/* Dialogs */}
      <ContactDialog open={contactOpen} onClose={handleCloseContact} />

      <Dialog open={roadMapOpen} onClose={handleCloseRoadmap}>
        <DialogTitle>Roadmap</DialogTitle>
        <DialogContent dividers>
          <RoadMap />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRoadmap} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={paymentDialogOpen} onClose={handleClosePaymentDialog}>
        <DialogTitle>Subscribe to Typecelerate</DialogTitle>
        <DialogContent dividers>
          {isUserLoading ? (
            <CircularProgress />
          ) : currUser ? (
            <PayPalSubscriptionButton userId={currUser.uid} />
          ) : (
            <Typography variant="body2" paragraph>
              You need to be logged in to subscribe to Typecelerate.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePaymentDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

};

export default SidebarMenu;