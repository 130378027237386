export const createSteps = (setIsFocused, setIsSidebarOpen) => [
    {
      content: `Welcome to Typecelerate, A typing practice app that helps you improve your typing speed and accuracy. Let's take a quick tour of the app.`,
      position: 'center',
      action: () => {
        setTimeout(() => {
          setIsSidebarOpen(false);
        }, 300);
      }
    },
    {
      selector: '[data-tour="auth-button"]',
      content: 'You can login here to save your progress and high-scores.',
      action: () => {
        setTimeout(() => {
          setIsSidebarOpen(false);
        }, 300);
      }
    },
    {
      selector: '[data-tour="theme-selection-button"]',
      content: 'This is the theme selector. You can change the appearance of the app here. Select a color palette that you find pleasing before going to the next step.',
      highlightedSelectors: ['[data-tour="theme-selection-button"]', '[data-tour="theme-selection-menu"]'],
      mutationObservables: ['[data-tour="theme-selection-button"]', '[data-tour="theme-selection-menu"]'],
      position: 'right',
      stepInteraction: true,
      padding: 10,
      margin: 10
    },
    {
      selector: '[data-tour="onboarding"]',
      content: 'If you ever get lost, click here to start the onboarding tour again.',
      action: () => {
        setTimeout(() => {
          setIsSidebarOpen(true);
        }, 300);
      }
    },
    {
      selector: '[data-tour="typing-test"]',
      content: `This is the typing test. It will contain random words in the 'analysis' stage, and targeted words in the 'practice' stage.
      Type the words you see in the box.
      When you are done, go to the next step in the tour.`,
      action: () => {
        setTimeout(() => {
          setIsSidebarOpen(false);
          setIsFocused(true);
        }, 300);
      }
    },
    {
      selector: '[data-tour="typing-test-results"]',
      content: 'After each test you can see your typing speed and accuracy here.',
    },
    {
      selector: '[data-tour="typing-test"]',
      content: 'Do a second typing test to finish the analysis stage.',
      action: () => {
        setTimeout(() => {
          setIsFocused(true);
        }, 300);
      }
    },
    {
      selector: '[data-tour="slow-patterns"]',
      content: 'Here we collect the patterns that you type slowly. The typing test will contain these patterns to help you improve your typing speed.',
    },
    {
      selector: '[data-tour="inaccurate-patterns"]',
      content: 'Here we collect the patterns that you type incorrectly. The typing test will contain these patterns to help you improve your accuracy.',
    },
    {
      selector: '[data-tour="typing-test"]',
      content: 'Do a third test, to see what a tergeted practice test looks like. This test will contain words that are taylored to your weaknesses based on the previous tests.',
      action: () => {
        setTimeout(() => {
          setIsFocused(true);
        }, 300);
      }
    },
    {
      selector: '[data-tour="inner-dashboard"]',
      content: 'This dashboard offers fine-grained control over the collection, analysis and usage of user weaknesses. for more details, hover over the question mark icons.',
    },
    {
      selector: '[data-tour="custom-user-patterns"]',
      content: 'These input boxes allow you to specify patterns you want to include or exclude from the tests.',
    },
    {
      selector: '[data-tour="save-load-profiles"]',
      content: 'We allow users to save custom patterns into named profiles. You can load these profiles later to quickly switch between different sets of patterns. Check out the built-in profiles we prepared.',
    },
    {
      selector: '[data-tour="user-statistics"]',
      content: 'We save your high-scores so you can track your progress.',
    },
    {
      selector: '[data-tour="contact"]',
      content: 'For bug reporting or feature requests, click here to contact us.',
    },
    {
      content: `You have completed the tour of Typecelerate. Enjoy your typing practice`,
      position: 'center',
      action: () => {
        setIsSidebarOpen(true);
      }
    },
  ]

