import React, { useContext, useState, useEffect, useRef } from 'react';
import { MenuItem, FormControl, Menu, Button, Box, Typography } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import palettes from '../themes/palettes.js';
import { AppContext } from '../contexts/AppContext.jsx'
import { PaletteGrid, PaletteGrid2 } from './PaletteGrid';

const paletteNames = Object.keys(palettes);

const PaletteSelector = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { displayedPalette, setDisplayedPalette } = useContext(AppContext);
  const clickedPalette = useRef(displayedPalette);
  const hoveredPalette = useRef(displayedPalette);
  const hoverTimer = useRef(null);
  const isMenuOpen = Boolean(anchorEl);
  const { setIsFocused, } = useContext(AppContext);

  const handleClickOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setDisplayedPalette(clickedPalette.current);
    setIsFocused(true)
  };

  const handleClickPalette = (palette) => {
    if (isMenuOpen) {
      clickedPalette.current = palette;
      setDisplayedPalette(palette);
      setAnchorEl(null);
      setIsFocused(true);
    }
  };

  const handleMouseEnter = (palette) => {
    hoveredPalette.current = palette;
    hoverTimer.current = setTimeout(() => {
      if (isMenuOpen) {
        setDisplayedPalette(palette);
      }
    }, 500);
  };

  const handleMouseLeave = () => {
    hoveredPalette.current = null;
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current);
    }
  };

  useEffect(() => {
    return () => {
      if (hoverTimer.current) {
        clearTimeout(hoverTimer.current);
      }
    };
  }, []);

  return (
    <FormControl variant="outlined" size="small" data-tour="theme-selection-button">
      <Button onClick={handleClickOpenMenu} endIcon={<ArrowDropDownIcon />}>
        <PaletteGrid2 palette={displayedPalette} />
        {displayedPalette}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        data-tour="theme-selection-menu"
      >
        {paletteNames.map((palette) => (
          <MenuItem
            key={palette}
            onClick={() => handleClickPalette(palette)}
            onMouseEnter={() => handleMouseEnter(palette)}
            onMouseLeave={handleMouseLeave}
            sx={{
              backgroundColor: palettes[palette].palette.background.default,
              color: palettes[palette].palette.text.primary,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              // '&:hover': {
              //   backgroundColor: palettes[palette].palette.background,  // Keep background the same on hover
              // }
            }}
          >
            <Typography sx={{ mr: 4 }}>
              {palette}
            </Typography>
            <PaletteGrid2 palette={palette} />
          </MenuItem>
        ))}
      </Menu>
    </FormControl>
  );
};

export { PaletteSelector };