import React, { useState, useContext, useCallback } from 'react';
import { TextField, MenuItem, Select, IconButton, Box, Button, Typography } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { MainContentContext  } from '../contexts/MainContentContext'
import { SuperMainContentContext } from '../contexts/SuperMainContext.jsx'
import { builtInProfiles } from '../utils/builtInProfiles'

//TODO nimw - Clause suggested I use useReducer for this component
//It even gave me working code (I tested manually), but I don't want to do it before I understand it

// For the saving/loading profile part
const ProfilesMenu = ({ defaultOptions }) => {
  const [options, setOptions] = useState(defaultOptions);
  const [inputValue, setInputValue] = useState('');
  // const [loadedProfile, setLoadedProfile] = useState('')
  const { loadedProfile, setLoadedProfile } = useContext(MainContentContext);
  const { setIncludedPatterns, includedPatterns, setExcludedPatterns, excludedPatterns } = useContext(SuperMainContentContext);
  const [additionalOptions, setAdditionalOptions] = useState({});

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  //TODO rhig: Do these 3 functions make sense?
  //Meaning, can they be easily changed to use a DB 
  //instead of the state variable "addtionalOptions" 
  //once we have a backend?
  const addOptionToDB = (name, include, exclude) => {
    setAdditionalOptions({
      ...additionalOptions,
      [name]: {
        "include": include,
        "exclude": exclude
      }
    });
  }

  const deleteOptionFromDB = (name) => {
    const { [name]: _, ...rest } = additionalOptions;
    setAdditionalOptions(rest);
  }

  const modifyOptionInDB = (name, include, exclude) => {
    if (additionalOptions[name]) {
      // Update the option's include and exclude properties
      setAdditionalOptions({
        ...additionalOptions,
        [name]: {
          ...additionalOptions[name],
          include: include,
          exclude: exclude
        }
      });
    } else {
      console.error(`Option with name ${name} not found`);
    }
  };


  const handleInputKeyPress = (event) => {
    if ((event.key === 'Enter' || event.type === 'click') && inputValue.trim() !== '') {
      setOptions([...options, inputValue.trim()]);
      setInputValue('');
      setLoadedProfile(inputValue.trim());
      addOptionToDB(inputValue.trim(), includedPatterns, excludedPatterns);
    }
  };

const getIncludeArrFromDB = (selectedOption) => {
  return additionalOptions[selectedOption]?.include || [];
}

const getExcludeArrFromDB = (selectedOption) => {
  return additionalOptions[selectedOption]?.exclude || [];
}

const handleOptionClick = useCallback((selectedOption) => {
  setLoadedProfile(selectedOption);
  setIncludedPatterns(
    builtInProfiles[selectedOption]?.include || getIncludeArrFromDB(selectedOption) 
  );
  setExcludedPatterns(
    builtInProfiles[selectedOption]?.exclude || getExcludeArrFromDB(selectedOption)
  ); 
}, [builtInProfiles, getIncludeArrFromDB, getExcludeArrFromDB]);

  const handleDeleteOption = (index) => {
    const optionToDelete = options[index];
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);
    deleteOptionFromDB(optionToDelete);
    if (loadedProfile === optionToDelete) {
      setLoadedProfile('');
    }
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{ width: '100%' }}>
        <Grid xs={8}>
          <TextField
            label="Create New Profile..."
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleInputKeyPress}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid xs={4}>
          <Button
            variant='contained'
            fullWidth
            onClick={handleInputKeyPress}
          >
            <Typography align='left'>Create</Typography>
          </Button>
        </Grid>
        <Grid xs={12}>
          <Select
            value=""
            onChange={(event) => handleOptionClick(event.target.value)}
            displayEmpty
            variant="outlined"
            size="small"
            fullWidth
          >
            <MenuItem value="" disabled>
              {loadedProfile === '' ? 'Load Profile...' : loadedProfile}
            </MenuItem>
            {options.map((option, index) => (
              <MenuItem key={index} value={option}>
                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                  {option}
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDeleteOption(index);
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button variant='contained'
            onClick={() => modifyOptionInDB(loadedProfile, includedPatterns, excludedPatterns)}
          >
            <Typography>Modify Current Profile</Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

};

export { ProfilesMenu};
