import { Box } from '@mui/material'

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        width: '100%',
        backgroundColor: 'transparent',
        textAlign: 'center',
        mt: '10em',
      }}
    >
     © 2024 Footer, Inc.
    </Box>
  );
}

export { Footer };