import React, { useContext } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Stack } from '@mui/material';
import { useMediaQuery } from '@mui/material';

import { TTDropdownMenu } from './TTDropdownMenu';
import { MainContentContext } from '../contexts/MainContentContext';
import { SuperMainContentContext } from '../contexts/SuperMainContext.jsx'
import { AppContext } from '../contexts/AppContext';
import {
  wordListSizes, testLengths, languages, defaultTestLength,
  defaultWordList, defaultLanguage
} from '../utils/defaultValues';

function TypingTestOptions(props) {
  //TODO Add possibility to add custom word lists
  //TODO consider adding MT200 : the license is bad for that [too open sourcey], however I'm
  //not sure they can license a list of words. On the other hand, I'm not sure
  //it'll be ok for me to publicly announce that it's the monkeytype 200 word list

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const { setIsFocused } = useContext(AppContext);

  const {  setInaccuratePatterns, setSlowPatterns, } =
    useContext(MainContentContext);

  const { selectedLanguage, setSelectedLanguage, selectedTestLength, setSelectedTestLength, selectedWordList, setSelectedWordList,
      setIncludedPatterns, setExcludedPatterns } = useContext(SuperMainContentContext);

  const handleTestLengthChange = (event) => {
    setSelectedTestLength(event.target.value);
    console.log(event.target.value);
    setIsFocused(true);
  };

  const handleTestLanguageChange = (event) => {
    //TODO create a function in mainContentContext to clear the patterns
    setSelectedLanguage(event.target.value);
    setInaccuratePatterns([]);
    setSlowPatterns([]);
    setIncludedPatterns([]);
    setExcludedPatterns([]);
    console.log(event.target.value);
    setIsFocused(true);
  };

  const handleTestWordListChange = (event) => {
    setSelectedWordList(event.target.value);
    console.log(event.target.value);
    setIsFocused(true);
  };

  return (

    <Stack
      direction={isSmallScreen ? 'column' : 'row'}
      spacing={6}
      sx={{ display: 'flex', alignItems: 'flex-end', mx: '1em', my: '1.0em' }}
    >
      <TTDropdownMenu title="Language"
        options={languages}
        defaultOption={defaultLanguage}
        onChange={handleTestLanguageChange}
        value={selectedLanguage || defaultLanguage}
        />
      <TTDropdownMenu title="Word List"
        options={wordListSizes}
        defaultOption={defaultWordList}
        onChange={handleTestWordListChange}
        value={selectedWordList || defaultWordList}
      />      
      <TTDropdownMenu title="Test Length"
        options={testLengths}
        defaultOption={defaultTestLength}
        onChange={handleTestLengthChange}
        value={selectedTestLength || defaultTestLength}
      />
    </Stack>



    // <Grid container spacing={2} sx={{ mx: "1em", my: "0.5em" }}>
    //   <Grid xs={12} sm={12} md={12} lg={4}>
    //     <TTDropdownMenu title="Language"
    //       options={languageOptions}
    //       defaultOption={languageOptions[0]}
    //       onChange={handleTestLanguageChange}
    //       value={selectedLanguage || languageOptions[0]}
    //     />
    //   </Grid>
    //   <Grid xs={12} sm={12} md={12} lg={4}>
    //     <TTDropdownMenu title="Word List"
    //       options={wordList}
    //       defaultOption={wordList[0]}
    //       onChange={handleTestWordListChange}
    //       value={selectedWordList || wordList[0]}
    //     />
    //   </Grid>
    //   <Grid xs={12} sm={12} md={12} lg={4}>
    //     <TTDropdownMenu title="Test Length"
    //       options={testLength}
    //       defaultOption={testLength[0]}
    //       onChange={handleTestLengthChange}
    //       value={selectedTestLength || testLength[0]}
    //     />
    //   </Grid>
    // </Grid>

  );
}

export { TypingTestOptions };
