import React from 'react';
import { Typography, List, ListItem, ListItemText, Box } from '@mui/material';

const AppInfo = () => {
  return (
    <Box sx={{ p: 2, width: "50%" }}>
      <Typography variant="h4" gutterBottom>
        Typecelerate
      </Typography>
      
      <Typography variant="body1" paragraph>
        Typecelerate is designed to help you improve your typing skills through detailed analysis and customized practice. Whether you're a beginner or an advanced typist, our tool offers features to support your growth.
      </Typography>
      
      <Typography variant="h5" gutterBottom>
        Key Features:
      </Typography>
      <List>
        <ListItem>
          <ListItemText 
            primary={<Typography variant="h6" color="black">Pattern Analysis</Typography>}
            secondary={
              <Typography variant="body1" style={{ marginTop: '0.5em' }}>
                Typecelerate analyzes your typing performance and pinpoints specific letter combinations and words that slow you down or cause errors.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary={<Typography variant="h6" color="black">Weakness-Targeted Practice</Typography>}
            secondary={
              <Typography variant="body1" style={{ marginTop: '0.5em' }}>
                Using your identified weak points, Typecelerate generates a new,
                tailored typing test that focuses specifically on your areas needing improvement.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary={<Typography variant="h6" color="black">Personalized Practice</Typography>}
            secondary={
              <Typography variant="body1" style={{ marginTop: '0.5em' }}>
                Create custom profiles to include or exclude patterns of your choice.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary={<Typography variant="h6" color="black">Customizable Tests</Typography>}
            secondary={
              <Typography variant="body1" style={{ marginTop: '0.5em' }}>
                Choose from various word lists, languages, and text lengths.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary={<Typography variant="h6" color="black">Progress Tracking</Typography>}
            secondary={
              <Typography variant="body1" style={{ marginTop: '0.5em' }}>
                Monitor your improvement over time with detailed statistics (coming soon).
              </Typography>
            }
          />
        </ListItem>
      </List>

      <Typography variant="h6" gutterBottom>
        How to Use:
      </Typography>
      <Typography variant="body1" paragraph>
        You can start typing, and Typecelerate takes care of the rest.
        If you want to customize your practice, you can adjust the test settings to your liking.
        If you're not sure what a particular feature does, hover over the info icon for more information.
        Don't be afraid to play around with the settings, there's a 'Restore Defaults' button if you want to start fresh.
        Current keyboard shortcuts include: esc to quit the test, and ctrl+shift+f to focus the typing test box.
      </Typography>

      <Typography variant="body1" paragraph sx={{ mt: 2 }}>
        Remember, consistent practice is key to improving your typing skills. Use Typecelerate regularly, focus on your weak areas, and watch your speed and accuracy improve over time!
      </Typography>
    </Box>
  );
};

export { AppInfo };