import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Divider, Chip, Box, Typography, Tooltip, Slider, Stack, Button, Container, Card } from '@mui/material'
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import ConstructionIcon from '@mui/icons-material/Construction';
import TuneIcon from '@mui/icons-material/Tune';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

import { PatternsCard } from './PatternsCard'
import { InnerDashboard } from './InnerDashboard'
import { ProfilesMenu } from './ProfilesMenu'
import { SliderWrapper, SliderWrapper2 } from './SliderWrapper'
import { TTAccordion } from './TTAccordion'
import { MainContentContext  } from '../contexts/MainContentContext'
import { SuperMainContentContext } from '../contexts/SuperMainContext.jsx'
import { RestoreDefaultSettingsButton } from './RestoreDefaultSettingsButton'
import { builtInProfiles } from '../utils/builtInProfiles'
import {
  missedPatternsTooltip, slowPatternsTooltip, userIncludedPatternsTooltip,
  userExcludedPatternsTooltip, saveLoadTooltip
} from '../utils/toolTipTexts.js'



const CustomPatternsDashboard = () => {
  const theme = useTheme();

  const { includedPatterns, setIncludedPatterns, excludedPatterns, setExcludedPatterns,
  } = useContext(SuperMainContentContext);

  return (

    <>
        <Divider textAlign='center' sx={{
          typography: 'p',
          textAlign: 'center',
          borderRadius: 2,
          m: '1em'
        }}>
          <Card
            sx={{
              borderRadius: 20,
              // bgcolor: theme.palette.background.default,
              bgcolor: theme.palette.background.paper,
              color: theme.palette.text.secondary,
            }}
            variant="outlined" >
            <Stack direction="row">
              <Box sx={{ display: 'flex', alignItems: 'center', mx: '10px' }}>
                <DashboardCustomizeIcon size={24} />
              </Box>
              <Typography variant="p" sx={{ pr: "30px" }}  >
                {"Custom Patterns"}
              </Typography>
            </Stack>
          </Card>
        </Divider>

        <Box data-tour="custom-user-patterns">
          <PatternsCard
            patterns={includedPatterns}
            text="Include Patterns"
            placeholder='Include patterns'
            label="Enter desired patterns"
            setValues={setIncludedPatterns}
            toolTipText={userIncludedPatternsTooltip} >
          </PatternsCard>
          <br />
          <PatternsCard
            patterns={excludedPatterns}
            text="Exclude Patterns"
            placeholder='Exclude patterns'
            label="Enter undesired patterns"
            setValues={setExcludedPatterns}
            toolTipText={userExcludedPatternsTooltip} />
          <br />
        </Box>
        {/* TODO add the ability to save and load profiles */}
        <Box data-tour="save-load-profiles"
          sx={{
            ...theme.customStyles.patternsBox,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ width: "100%", m: "0" }}>
            <Grid xs={10}>
              <ConstructionIcon sx={{ color: "red" }} />
              <Typography sx={{ mb: "1em", color: "red" }}>
                New profiles are only saved per session.
                Gone after refresh.
              </Typography>
              <Typography sx={{ mb: "1em" }}>Save Custom Patterns</Typography>
            </Grid>
            <Grid xs={2}>
              <Box sx={{ padding: 0, textAlign: "right", width: "100%" }}>
                <Tooltip title={saveLoadTooltip}>
                  <InfoIcon />
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          <ProfilesMenu defaultOptions={Object.keys(builtInProfiles)}
          />
        </Box>
</>
  )
}

const TestParamsDashboard = () => {

  const { inaccuratePatterns, setInaccuratePatterns, slowPatterns,
    setSlowPatterns, setSlowPatternsNum, setInaccuratePatternsNum,
    slowPatternsNum, inaccuratePatternsNum,  } = useContext(MainContentContext);

  const [ innerDashboardAccordionExpanded, setInnerDashboardAccordionExpanded ] = useState(true)
  const [ customPatternsaccordionExpanded, setCustomPatternsaccordionExpanded ] = useState(true)


  const handleInaccuratePatternsSlider = (event, newValue) => {
      setInaccuratePatternsNum(newValue);
  };

  const handleSlowPatternsSlider = (event, newValue) => {
      setSlowPatternsNum(newValue);
  };

  return (
    <Grid container spacing={4}>
      <Grid xs={12} sm={12} lg={6} >
        <PatternsCard
          dataTour="inaccurate-patterns"
          patterns={inaccuratePatterns}
          text="Your Mistyped Patterns"
          placeholder='Inaccurate Patterns'
          setValues={setInaccuratePatterns}
          toolTipText={missedPatternsTooltip} >
          <SliderWrapper
            label={`inaccurate Patterns: ${inaccuratePatternsNum}`}
            tooltipTitle={"Adjust the number of used mistyped patterns for the targeted practice"}
            tooltipPlacement="left-start"
          >
            <Slider
              color="secondary"
              value={inaccuratePatternsNum}
              onChange={handleInaccuratePatternsSlider}
              step={1}
              marks={[
                { value: 5, label: '5' },
                { value: 10, label: '10' },
                { value: 15, label: '15' }
              ]}
              min={5}
              max={15}
              sx={{
                width: "80%", mt: 0, ml: "1em",
                '& .MuiSlider-markLabel': {
                  color: 'text.primary', // Use primary text color for all labels
                },
              }}
            />
          </SliderWrapper>
        </PatternsCard>
        {/* <TTAccordion text="User Custom Patterns"
          Icon={DashboardCustomizeIcon}
          expanded={customPatternsaccordionExpanded}
          setExpanded={setCustomPatternsaccordionExpanded}
          width="100%"
        > */}
  
          <CustomPatternsDashboard />
        {/* </TTAccordion> */}
        <br />
        <RestoreDefaultSettingsButton />
      </Grid>
      <Grid xs={12} sm={12} lg={6}>
        <PatternsCard
          dataTour="slow-patterns"
          patterns={slowPatterns}
          text="Your Slow Patterns"
          setValues={setSlowPatterns}
          placeholder='Slow Patterns'
          toolTipText={slowPatternsTooltip} >
          <SliderWrapper
            label={`Slow Patterns: ${slowPatternsNum}`}
            tooltipTitle={"Adjust the number of used slow patterns for the targeted practice"}
            tooltipPlacement="left-start"
          >
            <Slider
              color="secondary"
              value={slowPatternsNum}
              onChange={handleSlowPatternsSlider}
              step={1}
              marks={[
                { value: 5, label: '5' },
                { value: 10, label: '10' },
                { value: 15, label: '15' }
              ]}
              min={5}
              max={15}
              sx={{
                width: "80%", mt: 0, ml: "1em",
                '& .MuiSlider-markLabel': {
                  color: 'text.primary', // Use primary text color for all labels
                },
              }} />
          </SliderWrapper>
        </PatternsCard>
        {/* <TTAccordion text="Advanced Settings"
          Icon={TuneIcon}
          expanded={innerDashboardAccordionExpanded}
          setExpanded={setInnerDashboardAccordionExpanded}
          width="100%"
        > */}
          <InnerDashboard />
        {/* </TTAccordion> */}
      </Grid>
    </Grid>
  );
}

export {TestParamsDashboard}