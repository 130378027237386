import { useState, useContext, useEffect, useRef, useCallback } from 'react'
import {  Container, Box, Snackbar, Alert, Stack, Typography, List, ListItem, ListItemText } from '@mui/material'
import PatternIcon from '@mui/icons-material/Pattern';
import BarChartIcon from '@mui/icons-material/BarChart';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { debounce } from 'lodash'; // Make sure to install lodash if you haven't already

// import KeyboardIcon from '@mui/icons-material/Keyboard';

import { TypingTest } from './TypingTest'
import { TTAccordion }  from './TTAccordion'
import { TestParamsDashboard } from './TestParamsDashboard'
import { MainContentProvider,  } from '../contexts/MainContentContext'
import { SuperMainContentContext } from '../contexts/SuperMainContext.jsx'
import {UserStatistics} from './UserStatistics'
import { AboveTest } from './AboveTest'
import  ProgressBar  from './ProgressBar'
import { AdComponent } from './AdComponent'

function MainContent() {
  const [showContent, setShowContent] = useState(true);
  const [focusMode, setFocusMode] = useState(true)
  const { includedPatterns, excludedPatterns, getNewTestBasedOnUserPatternsChanges,
    selectedTestLength, selectedWordList, avatarIcon, letterLimit } = useContext(SuperMainContentContext);

  const { patternsAccordionExpanded, setPatternsAccordionExpanded } = useContext(SuperMainContentContext);
  const { statisticsAccordionExpanded, setStatisticsAccordionExpanded } = useContext(SuperMainContentContext);
  const { algorithmFoundWords, desiredRandomness } = useContext(SuperMainContentContext);
  const [noWordsFoundAlertOpen, setNoWordsFoundAlertOpen] = useState(false);
  const [isHoveringNoWordsFoundAlert, setIsHoveringNoWordsFoundAlert] = useState(false);


  const showContentTrue = () => {
    setShowContent(true)
  }

  const showContentFalse = () => {
    setShowContent(false)
  }

  useEffect(() => {
    if (algorithmFoundWords === false) {
      setNoWordsFoundAlertOpen(true);
    }
  }, [algorithmFoundWords]);

  const handleCloseNoFoundWordsAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNoWordsFoundAlertOpen(false);
    setIsHoveringNoWordsFoundAlert(false); // Reset hover state when closing
  };

  const handleMouseEnterNoWordsFoundAlert = () => setIsHoveringNoWordsFoundAlert(true);

  const handleMouseLeaveNoWordsFoundAlert = () => {
    setIsHoveringNoWordsFoundAlert(false);
    // Only start the close timer if the alert is still open
    if (noWordsFoundAlertOpen) {
      setTimeout(() => {
        setNoWordsFoundAlertOpen((prevOpen) => {
          if (prevOpen && !isHoveringNoWordsFoundAlert) {
            return false;
          }
          return prevOpen;
        });
      }, 2000);
    }
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleCloseNoFoundWordsAlert}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const isInitialMount = useRef(true);

  
  //This weird technique from Claude makes it less "stuck" to enter multiple include/exclude patterns in a row.
  const DEBOUNCE_DELAY = 500; // Static 500ms delay
  const debouncedGetNewTestRef = useRef(null);

  useEffect(() => {
    // Create the debounced function
    debouncedGetNewTestRef.current = debounce(() => {
      console.log("Fetching new test based on changes to requested user patterns");
      getNewTestBasedOnUserPatternsChanges().catch(error => {
        console.error("Error fetching new test based on user patterns changes: ", error);
      });
    }, DEBOUNCE_DELAY);

    // Cleanup function to cancel any pending debounced calls
    return () => {
      if (debouncedGetNewTestRef.current) {
        debouncedGetNewTestRef.current.cancel();
      }
    };
  }, [getNewTestBasedOnUserPatternsChanges]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (debouncedGetNewTestRef.current) {
      debouncedGetNewTestRef.current();
    }
  }, [excludedPatterns, includedPatterns, selectedTestLength, selectedWordList, desiredRandomness, letterLimit]);

//This is how I wrote it.
// Change the typing test when the user makes a change to the patterns or basic test settings
// useEffect(() => {
//   if (isInitialMount.current) {
//     isInitialMount.current = false;
//   } else {
//     console.log("Fetching new test based on changes to requested user patterns");
//     const fetchData = async () => {
//       try {
//         await getNewTestBasedOnUserPatternsChanges();
//       } catch (error) {
//         console.error("Error fetching new test based on user patterns changes: ", error);
//       }
//     };
//     fetchData();
//   }
// }, [excludedPatterns, includedPatterns, selectedTestLength, selectedWordList, desiredRandomness]);

  
  return (
    <Container>
      <MainContentProvider>
        <Box sx={{
          opacity: (!focusMode || showContent) ? 1 : 0,
          transition: 'opacity 0.2s ease-in-out',
        }}>
          <Box sx={{ mb: "1em" }}>
            <AboveTest setFocusMode={setFocusMode} className="above-test" />
          </Box>
          <ProgressBar visible={!focusMode || showContent} icon={avatarIcon} />
        </Box>
        <TypingTest onStart={showContentFalse} onEnd={showContentTrue} />
        <Box sx={{
          opacity: (!focusMode || showContent) ? 1 : 0,
          transition: 'opacity 0.2s ease-in-out',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}>
          <Stack>
            <TTAccordion text="Discover your patterns"
              Icon={PatternIcon}
              expanded={patternsAccordionExpanded}
              setExpanded={setPatternsAccordionExpanded} >
              <TestParamsDashboard />
            </TTAccordion>
            <TTAccordion
              text="Track your progress"
              Icon={BarChartIcon}
              expanded={statisticsAccordionExpanded}
              setExpanded={setStatisticsAccordionExpanded}>
              <UserStatistics />
            </TTAccordion>
          </Stack>
        </Box>
        {/* TODO nimw : move to a different component */}
        <Snackbar
          open={noWordsFoundAlertOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={isHoveringNoWordsFoundAlert ? null : 20000}
          onClose={handleCloseNoFoundWordsAlert}
          onMouseEnter={handleMouseEnterNoWordsFoundAlert}
          onMouseLeave={handleMouseLeaveNoWordsFoundAlert}
        >
          <Alert
            onClose={handleCloseNoFoundWordsAlert}
            severity="error"
            sx={{ width: '100%' }}
            action={action}
          >
            <Typography>
              Unable to find words with the given criteria.
              Consider the following actions to avoid this error (in order of effectiveness):
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  <Typography>Remove some patterns from the "exclude" list.</Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Typography>Use a larger word pool (10k for example).</Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Typography>Reduce "Words History" value.</Typography>
                </ListItemText>
              </ListItem>
            </List>
            <Typography>
              Please contact if you believe there should not have been an issue.
              Provide a screenshot of the entire page if possible.
            </Typography>
          </Alert>
        </Snackbar>
      </MainContentProvider>

      <Box sx={{
        opacity: (!focusMode || showContent) ? 1 : 0, 
        transition: 'opacity 0.2s ease-in-out',
      }}>
        <AdComponent
          position='right'
          adSlot='3143179523'
        />
        {/* <AdComponent
          position='top'
          adSlot='3143179523'
        /> */}
      </Box>
    </Container>
  );

}

export { MainContent };