
import React, { useContext, } from 'react';
import { Box, Dialog } from '@mui/material'
import { AppContext } from '../contexts/AppContext.jsx'

function TTDialog(props) {
  const {setIsFocused} = useContext(AppContext);

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown' || reason === 'enterKeyDown') {
      // Handle closing the dialog on backdrop click here
      props.onClose?.(); // Optional: Call the onClose prop passed from outside if available
      setIsFocused(true);
    }
  };

const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    props.onClose?.();
    setIsFocused(true);
  }
};

  return (
    <Dialog open={props.isOpen} onClose={handleClose} onKeyDown={handleKeyDown} >
      <Box>
        {props.children}
      </Box>
    </Dialog>
  )
}

export { TTDialog };