import './App.css';
import React, { useContext, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Typography } from '@mui/material'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import LinearProgress from '@mui/material/LinearProgress';
import Backdrop from '@mui/material/Backdrop';

// import { useAuth0 } from "@auth0/auth0-react";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './utils/firebaseInit.js';
import { TourProvider } from '@reactour/tour'
import { HelmetProvider, Helmet } from 'react-helmet-async';

import { TTDialog } from './components/TTDialog'
import { Layout } from './components/Layout'
import { MainContent } from './components/MainContent'
import NonDisclosureAgreement from './components/NonDisclosureAgreement'
import { PrivacyPolicyTermsOfServicePage } from './components/PrivacyPolicyTermsOfServicePage';
import { AppInfo } from './components/AppInfo.jsx';
import Contributors from './components/Contributors';

import { AppContext } from './contexts/AppContext.jsx'
import { SuperMainProvider } from './contexts/SuperMainContext.jsx'

import palettes from './themes/palettes.js';

import { createSteps } from './utils/tourSteps.js';
import createCommonSettings from './utils/createCommonSettings';


function App() {
  // const { isLoading: isAuth0Loading, isAuthenticated } = useAuth0(); //Auth0
  // const auth = getAuth(firebaseApp); //Firebase

  // const [user, setUser] = useState(null);
  const {currUser, setCurrUser} = useContext(AppContext);
  // const [isUserLoading, setIsUserLoading] = useState(true);
  const {isUserLoading, setIsUserLoading} = useContext(AppContext);

  const { displayedPalette, setIsFocused, displayedFont, setIsSidebarOpen } = useContext(AppContext);
  const commonSettings = createCommonSettings(displayedPalette, displayedFont)

  const steps = useMemo(() => createSteps(setIsFocused, setIsSidebarOpen), [setIsFocused, setIsSidebarOpen]);

  useEffect(() => {
    console.log("App rendered. Current user:", currUser);
    console.log("Is user loading:", isUserLoading);
  }, [currUser, isUserLoading]);


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.shiftKey && event.key.length === 1 && event.key.toLowerCase() === 'f') {
        setIsFocused(true);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // console.log('App.js rendered');

  let theme = useMemo(() => {
    return createTheme({
      ...palettes[displayedPalette],
      ...commonSettings,
      transitions: {
        duration: {
          colorMode: 1000, // Duration of the color transition in milliseconds
        },
      },
    });
  }, [displayedPalette, commonSettings]);

  theme = responsiveFontSizes(theme);

// theme = responsiveFontSizes(theme, {
//   breakpoints: ['sm', 'md', 'lg', 'xl', '4k'],
//   factor: 2,
//   variants: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2'],
// });

  const showNDA = !isUserLoading && !currUser;

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <SuperMainProvider>
          <CssBaseline />
          <TourProvider steps={steps}
            styles={{
              popover: (base) => ({
                ...base,
                '--reactour-accent': theme.palette.primary.main,
                background: theme.palette.background.paper,
                color: theme.palette.text.primary,
              }),
              dot: (base, { current }) => ({
                ...base,
                borderColor: current ? theme.palette.primary.main : theme.palette.divider,
                backgroundColor: current ? theme.palette.primary.main : 'transparent',
              }),
            }}
          >
            <Router>
              <Helmet>
                <title>Typecelerate - Improve Your Typing Skills</title>
                <meta name="description" content="Enhance your typing speed and accuracy with Typecelerate, a customizable typing practice tool." />
                <meta name="keywords" content="typing test, speed typing, touch typing" />
                <meta property="og:title" content="Typecelerate - Improve Your Typing Skills" />
                <meta property="og:description" content="Enhance your typing speed and accuracy with Typecelerate, a customizable typing practice tool." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://typecelerate.com" />
                {/* Add more meta tags as needed */}
                <link rel="canonical" href="https://typecelerate.com" />
              </Helmet>
              {isUserLoading ? (
                <Backdrop open={isUserLoading}
                  sx={{
                    zIndex: 130,
                    backgroundColor: theme.palette.background.default,
                    flexDirection: 'column',
                  }} >
                  <Typography>Loading</Typography>
                  <LinearProgress sx={{ width: '50%', marginBottom: 2 }} />
                </Backdrop>
              ) : null}

              {showNDA && (
                <TTDialog isOpen={true}>
                  <NonDisclosureAgreement />
                </TTDialog>
              )}
              <Routes>
                <Route path="/contributors" element={
                  <Layout>
                    <Contributors />
                  </Layout>
                } />
                <Route path="/privacy-policy-and-terms-of-service" element={
                  <Layout>
                    <PrivacyPolicyTermsOfServicePage />
                  </Layout>
                } />
                <Route path="/info" element={
                  <Layout>
                    <AppInfo />
                  </Layout>
                } />
                <Route path="/" element={
                  <Layout>
                    <MainContent />
                  </Layout>
                } />
              </Routes>
            </Router>
          </TourProvider>
        </SuperMainProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;