import React, { createContext, useState, useEffect } from 'react';
import { onAuthStateChanged, getRedirectResult } from 'firebase/auth';
import { auth } from '../utils/firebaseInit.js';
import palettes from '../themes/palettes.js';

const paletteNames = Object.keys(palettes);

  // Function to validate the palette name
  const validatePaletteName = (paletteName) => {
    return paletteNames.includes(paletteName) ? paletteName : paletteNames[0];
  };

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [displayedPalette, setDisplayedPalette] = useState(() => {
    const savedPalette = localStorage.getItem('theme');
    return validatePaletteName(savedPalette || 'canaryYellow');
  });

  const [displayedFont, setDisplayedFont] = useState(() => {
    const savedFont = localStorage.getItem('font');
    return savedFont || 'Courier Prime';
  });


  const [currUser, setCurrUser] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [isFocused, setIsFocused] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    console.log("Setting up auth state listener in AppContext");
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("AppContext: Auth state changed", user);
      if (user) {
        console.log("User is signed in:", user.uid);
        setCurrUser(user);
      } else {
        console.log("User is signed out");
        setCurrUser(null);
      }
      setIsUserLoading(false);
    });

    // Handle redirect result
    getRedirectResult(auth)
      .then((result) => {
        console.log("Redirect result:", result);
        if (result) {
          console.log("Redirect result user:", result.user);
          setCurrUser(result.user);
        } else {
          console.log("No redirect result");
        }
      })
      .catch((error) => {
        console.error("Error getting redirect result", error.code, error.message);
      });

    return () => {
      console.log("Cleaning up auth state listener in AppContext");
      unsubscribe();
    };
  }, []);



  useEffect(() => {
    console.log('Theme changed:', displayedPalette);
    localStorage.setItem('theme', displayedPalette);
  }, [displayedPalette]);

  const contextValue = { 
    displayedPalette, setDisplayedPalette, currUser, setCurrUser,
    isUserLoading, isFocused, setIsFocused, setIsUserLoading,
    displayedFont, setDisplayedFont, isSidebarOpen, setIsSidebarOpen,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};