import React from 'react';
import { Box, SvgIcon } from '@mui/material';
import palettes from '../themes/palettes.js';

const PaletteGrid = ({ palette: paletteName, sx }) => {
  const palette = palettes[paletteName]?.palette;

  const defaultColors = ['#ccc', '#999', '#666', '#333', '#000'];

  const getColor = (path) => {
    if (!palette) return '#ccc';
    try {
      return path.split('.').reduce((obj, key) => obj[key], palette) || '#ccc';
    } catch (error) {
      console.warn(`Failed to get color for path: ${path}`, error);
      return '#ccc';
    }
  };

  const colors = palette ? [
    getColor('primary.main'),
    getColor('secondary.main'),
    getColor('background.paper'),
    getColor('text.primary'),
    getColor('text.secondary')
  ] : defaultColors;


  const defaultDividerColor = 'rgba(0, 0, 0, 0.12)';

  const dividerColor = getColor('divider') || defaultDividerColor;

  const rotationAngle = 15;
  const totalAngle = (colors.length - 1) * rotationAngle;
  const startAngle = -totalAngle / 2;


  return (
    <SvgIcon
      // viewBox="0 0 150 100"  // Increased the viewBox to accommodate larger rectangles
      sx={{
        width: '4em',  // Manually set the size here
        height: '3em',
        // display: 'inline-block',
        // fontSize: 'inherit',
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100 60">
        {colors.map((color, index) => (
          <g key={index} transform={`rotate(${startAngle + index * rotationAngle}, 50, 55)`}>
            <rect
              width="45"  // Increased size of rectangles
              height="70"  // Increased size of rectangles
              fill={color}
              x="27"      // Reposition to fit the new size
              y="5"       // Reposition to fit the new size
              stroke={dividerColor}
              strokeWidth="2"  // Increased stroke width for visibility
            />
          </g>
        ))}
      </svg>
    </SvgIcon>
  );
};







const PaletteGrid2 = ({ palette }) => {
  const ColorSquare = ({ color }) => (
    <Box
      sx={{
        width: 12,
        height: 12,
        backgroundColor: color,
        display: 'inline-block',
        marginRight: 0.5,
      }}
    />
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box 
        sx={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(3, 12px)', 
          gap: 0, 
          marginRight: 1,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: palettes[palette].palette.divider,
          width: 'fit-content',
          height: 'fit-content'
        }}
      >
        <ColorSquare color={palettes[palette].palette.primary.main} />
        <ColorSquare color={palettes[palette].palette.secondary.main} />
        <ColorSquare color={palettes[palette].palette.background.default} />
        <ColorSquare color={palettes[palette].palette.background.paper} />
        <ColorSquare color={palettes[palette].palette.text.primary} />
        <ColorSquare color={palettes[palette].palette.text.secondary} />
      </Box>
    </Box>

  );
}

export { PaletteGrid, PaletteGrid2 };