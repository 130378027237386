import React, { useContext, useState, useEffect, useRef } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, Typography, Tooltip, Fade, useTheme } from '@mui/material';
import { MainContentContext } from '../contexts/MainContentContext';
// import { SuperMainContentContext } from '../contexts/SuperMainContext';
import TimelineIcon from '@mui/icons-material/Timeline';
import { VscGraphScatter } from "react-icons/vsc";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

import { AppContext } from '../contexts/AppContext';

const IconWrapper = ({ icon: Icon, progress, tooltipText, tooltipOpen, isFocused, visible }) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={tooltipText}
      placement="right-end"
      arrow
      open={tooltipOpen && isFocused && visible}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-20, 0],
            },
          },
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            whiteSpace: 'nowrap',
            maxWidth: 'none',
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            '& .MuiTooltip-arrow': {
              color: theme.palette.background.paper,
            },
          },
        },
      }}
    >
      <Icon
        sx={{
          position: 'absolute',
          top: '-50%',
          left: `${progress}%`,
          transform: 'translateX(-50%)',
          transition: 'left 0.3s ease-in-out',
          color: theme.palette.text.primary,
        }}
      />
    </Tooltip>
  );
};

const ProgressBar = ({ visible, icon: Icon }) => {
  const [position, setPosition] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(true);
  const theme = useTheme();
  const tooltipTimeoutRef = useRef(null);

  const { desiredRefreshInterval, testCount, randomDuration } = useContext(MainContentContext);
  const { isFocused } = useContext(AppContext);

  useEffect(() => {
    if (desiredRefreshInterval > 0) {
      setPosition(testCount % desiredRefreshInterval);
    } else {
      setPosition(testCount);
    }
  }, [testCount, desiredRefreshInterval]);

  useEffect(() => {
    setTooltipOpen(false);
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
    }
    tooltipTimeoutRef.current = setTimeout(() => {
      setTooltipOpen(true);
    }, 300);
    return () => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
    };
  }, [position]);

  let progress;
  

  const tooltipText = desiredRefreshInterval === 0 ?
    `Test: ${position + 1}/∞ in session.${position === 0 ?
      ' Start typing the words you see in the box.' : ''}`
    : `Test: ${position + 1}/${desiredRefreshInterval} in session.${position === 0 ?
      ' Start typing the words you see in the box.' : ''}`;

  // const randomWidth = (randomDuration / desiredRefreshInterval) * 100;
  let randomWidth;
  if ((desiredRefreshInterval === 0) && (randomDuration !== 0)) {
    randomWidth = 0.2 * 100;
  }
  else {
    randomWidth = (randomDuration / desiredRefreshInterval) * 100;
  }

  let nonRandomWidth;
  
  if (desiredRefreshInterval === 0) {
    nonRandomWidth = 100 - randomWidth;
  } else {
    nonRandomWidth = ((desiredRefreshInterval - randomDuration) / desiredRefreshInterval) * 100;
  }

  if (desiredRefreshInterval === 0) {
    if (testCount < randomDuration) {
      progress = (testCount / randomDuration) * 20 + 2;
    } else {
      // progress = 20 + 2;
      progress = randomWidth + nonRandomWidth / 2;
    }
  } else {
    progress = (position / desiredRefreshInterval) * 100 + 2;
  }

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '1em', display: 'flex', mb: "1em" }}>
      {randomDuration > 0 ?
      <Box
        sx={{
          backgroundColor: alpha(theme.palette.info.light, 0.2),
          borderRadius: '20px 0 0 20px',
          height: "100%",
          width: `${randomWidth}%`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <VscGraphScatter />
        {randomWidth > 15 && (
          <Typography variant="body2" sx={{ ml: 1 }}>Random Analysis</Typography>
        )}
      </Box>
      : null}
      <Box
        sx={{
          backgroundColor: alpha(theme.palette.success.light, 0.2),
          borderRadius: '0 20px 20px 0',
          height: "100%",
          width: `${nonRandomWidth}%`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {desiredRefreshInterval === 0 && <AllInclusiveIcon />}
        <TimelineIcon />
        {nonRandomWidth > 15 && (
          <Typography variant="body2" sx={{ ml: 1 }}>Targeted Practice</Typography>

        )}
      </Box>
      <IconWrapper icon={Icon} progress={progress} tooltipText={tooltipText} tooltipOpen={tooltipOpen} isFocused={isFocused} visible={visible} />
    </Box>
  );
};

export default ProgressBar;