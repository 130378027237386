import React, { createContext, useState, useEffect, useRef } from 'react';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';

import { SingleTestAnalysis } from '../utils/mainAlgorithm';
import { defaultDesiredRandomness, testDurations, languageCodes,
  wordListSizes, testLengths, defaultLanguage, defaultWordList,
  defaultTestLength, defaultLetterLimit } from '../utils/defaultValues';
import avatarIcons from '../utils/avatarIcons.js';

import wordLists from '../assets/wordLists.js';

export function getTestConfiguration(selectedTestLength, selectedWordList, language = 'English') {
  const testDuration = testDurations[selectedTestLength];
  if (testDuration === undefined) {
    throw new Error("Invalid test length");
  }

  const languageCode = languageCodes[language];
  if (!languageCode) {
    throw new Error("Invalid language");
  }

  if (!wordListSizes.includes(selectedWordList)) {
    throw new Error("Invalid word list size");
  }

  const wordListKey = `${languageCode}_${selectedWordList}`;
  const wordList = wordLists[wordListKey];

  if (!wordList) {
    throw new Error("Word list not found for the specified language and size");
  }

  return { testDuration, wordList };
}

export const SuperMainContentContext = createContext();
export const SuperMainProvider = ({ children }) => {
const isFullyRandom = useRef(false);
const [testStatus, setTestStatus] = useState('');
const testAnalysisRef = useRef(new SingleTestAnalysis(isFullyRandom, setTestStatus));
const [typingTestText, setTypingTestText] = useState('');
const [algorithmFoundWords, setAlgorithmFoundWords] = useState(true); 
// const [avatarIcon, setAvatarIcon] = useState(ElderlyWomanIcon); 
//TODO nimw : keep in localStorage (and later in DB), together with the theme
  const [avatarIcon, setAvatarIcon] = useState(() => {
    const savedIconName = localStorage.getItem('avatarIcon');
    const savedIcon = avatarIcons.find(icon => icon.name === savedIconName);
    return savedIcon ? savedIcon.icon : TwoWheelerIcon;
  });

  useEffect(() => {
    const iconName = avatarIcons.find(icon => icon.icon === avatarIcon)?.name;
    if (iconName) {
      localStorage.setItem('avatarIcon', iconName);
    }
  }, [avatarIcon]);

  const [desiredRandomness, setDesiredRandomness] = useState(() => {
  const savedRandomness = localStorage.getItem('desiredRandomness');
    // Explicitly check for null to differentiate between "0" and "doesn't exist"
    if (savedRandomness === null) {
      return defaultDesiredRandomness; // Default value when nothing is saved
    }
    const parsedRandomness = parseInt(savedRandomness, /*radix*/10);
    // Use parsed value directly as we've already handled the null case
    return !isNaN(parsedRandomness) ? parsedRandomness : defaultDesiredRandomness;
  });

  useEffect(() => {
    // Save the value as a string; "0" is truthy here so it will be saved correctly
    localStorage.setItem('desiredRandomness', desiredRandomness.toString());
  }, [desiredRandomness]);
  const [excludedPatterns, setExcludedPatterns] = useState([]
    /*() => {
    const savedPatterns = localStorage.getItem('excludedPatterns');
    return savedPatterns ? savedPatterns.split(',') : [];
    }*/
  );

  // useEffect(() => {
  //   localStorage.setItem('excludedPatterns', excludedPatterns.toString());
  // }, [excludedPatterns]);

  const [includedPatterns, setIncludedPatterns] = useState([]
    /* () => {
    const savedPatterns = localStorage.getItem('includedPatterns');
    return savedPatterns ? savedPatterns.split(',') : [];
    }*/
  );

  // useEffect(() => {
  //   localStorage.setItem('includedPatterns', includedPatterns.toString());
  // }, [includedPatterns]);
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem('language');
    return savedLanguage || defaultLanguage;
  });

  useEffect(() => {
    localStorage.setItem('language', selectedLanguage);
  }, [selectedLanguage]);

  const [selectedWordList, setSelectedWordList] = useState(() => {
    const savedWordList = localStorage.getItem('wordList');
    return wordListSizes.includes(savedWordList) ? savedWordList : defaultWordList;
  });

  useEffect(() => {
    localStorage.setItem('wordList', selectedWordList);
  }, [selectedWordList]);

  const [selectedTestLength, setSelectedTestLength] = useState(() => {
    const savedTestLength = localStorage.getItem('testLength');
    return testLengths.includes(savedTestLength) ? savedTestLength : defaultTestLength;
  });

  const [letterLimit, setLetterLimit] = useState(() => {
    const savedLetterLimit = localStorage.getItem('letterLimit');
    const parsedLetterLimit = parseInt(savedLetterLimit, 10);
    return !isNaN(parsedLetterLimit) && parsedLetterLimit >= 0 ?
      parsedLetterLimit : defaultLetterLimit;
  });

  useEffect(() => {
    localStorage.setItem('letterLimit', letterLimit.toString());
  } , [letterLimit]);

  const [patternsAccordionExpanded, setPatternsAccordionExpanded] = useState(() => {
    const savedState = localStorage.getItem('patternsAccordionExpanded');
    return savedState === null ? true : savedState === 'true'; // Default to true if nothing is saved
  });

  useEffect(() => {
    localStorage.setItem('patternsAccordionExpanded', patternsAccordionExpanded.toString());
  }, [patternsAccordionExpanded]);

  const [statisticsAccordionExpanded, setStatisticsAccordionExpanded] = useState(() => {
    const savedState = localStorage.getItem('statisticsAccordionExpanded');
    return savedState === null ? true : savedState === 'true'; // Default to true if nothing is saved
  });

  useEffect(() => {
    localStorage.setItem('statisticsAccordionExpanded', statisticsAccordionExpanded.toString());
  }, [statisticsAccordionExpanded]);

  useEffect(() => {
    localStorage.setItem('testLength', selectedTestLength);
  }, [selectedTestLength]);

  const getNewTestBasedOnUserPatternsChanges = async () => {
    console.log("getNewTestBasedOnUserPatternsChanges")
    let fetchedText = '';
    testAnalysisRef.current.setUserRequestedPatterns(includedPatterns, excludedPatterns);
    const { testDuration, wordList } = getTestConfiguration(selectedTestLength, selectedWordList, selectedLanguage);
    fetchedText = await testAnalysisRef.current.getNextTest(testDuration, wordList,
      desiredRandomness, false, setAlgorithmFoundWords, letterLimit);
    setTypingTestText(fetchedText);
  };

  const clearHistoryManager = () => {
    testAnalysisRef.current.clearHistory();
  };

  const getRandomTest = async () => {
    console.log("getRandomTest")
    let fetchedText = '';
    testAnalysisRef.current.setUserRequestedPatterns(includedPatterns, excludedPatterns);
    const { testDuration, wordList } = getTestConfiguration(selectedTestLength, selectedWordList, selectedLanguage);
    fetchedText = await testAnalysisRef.current.getNextTest(testDuration, wordList, 100, true, setAlgorithmFoundWords);
    setTypingTestText(fetchedText);
  };

  const contextValue = {
    selectedWordList, setSelectedWordList, selectedLanguage, setSelectedLanguage,
    selectedTestLength, setSelectedTestLength, includedPatterns, setIncludedPatterns,
    excludedPatterns, setExcludedPatterns,testAnalysisRef,getNewTestBasedOnUserPatternsChanges,
    typingTestText, setTypingTestText, getRandomTest, desiredRandomness, setDesiredRandomness,
    patternsAccordionExpanded, setPatternsAccordionExpanded, statisticsAccordionExpanded,
    setStatisticsAccordionExpanded, clearHistoryManager, algorithmFoundWords,
    setAlgorithmFoundWords, isFullyRandom, testStatus, setTestStatus, avatarIcon, setAvatarIcon,
    letterLimit, setLetterLimit, 
  };

  return (
    <SuperMainContentContext.Provider value={contextValue}>
      {children}
    </SuperMainContentContext.Provider>
  );
}