import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ElderlyWomanIcon from '@mui/icons-material/ElderlyWoman';
import KayakingIcon from '@mui/icons-material/Kayaking';
import ElderlyIcon from '@mui/icons-material/Elderly';
import RowingIcon from '@mui/icons-material/Rowing';
import PoolIcon from '@mui/icons-material/Pool';
import SurfingIcon from '@mui/icons-material/Surfing';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import SkateboardingIcon from '@mui/icons-material/Skateboarding';
import SnowboardingIcon from '@mui/icons-material/Snowboarding';
import AssistWalkerIcon from '@mui/icons-material/AssistWalker';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ToysIcon from '@mui/icons-material/Toys';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import MopedIcon from '@mui/icons-material/Moped';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import SnowmobileIcon from '@mui/icons-material/Snowmobile';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import SailingIcon from '@mui/icons-material/Sailing';
// import MascotIcon from '../components/MascotIcon';

const avatarIcons = [
  { icon: DirectionsRunIcon, name: 'Running' },
  // { icon: MascotIcon, name: 'Mascot' },
  { icon: ElderlyWomanIcon, name: 'Elderly Woman' },
  { icon: KayakingIcon, name: 'Kayaking' },
  { icon: ElderlyIcon, name: 'Elderly' },
  { icon: RowingIcon, name: 'Rowing' },
  { icon: PoolIcon, name: 'Swimming' },
  { icon: SurfingIcon, name: 'Surfing' },
  { icon: AccessibleForwardIcon, name: 'Accessible' },
  { icon: SkateboardingIcon, name: 'Skateboarding' },
  { icon: SnowboardingIcon, name: 'Snowboarding' },
  { icon: AssistWalkerIcon, name: 'Walker' },
  { icon: DirectionsBikeIcon, name: 'Cycling' },
  { icon: AgricultureIcon, name: 'Farming' },
  { icon: ShoppingCartIcon, name: 'Shopping' },
  { icon: LocalShippingIcon, name: 'Shipping' },
  { icon: ToysIcon, name: 'Toys' },
  { icon: ChildFriendlyIcon, name: 'Child' },
  { icon: MopedIcon, name: 'Moped' },
  { icon: PedalBikeIcon, name: 'Bicycle' },
  { icon: SnowmobileIcon, name: 'Snowmobile' },
  { icon: TwoWheelerIcon, name: 'Motorcycle' },
  { icon: DeliveryDiningIcon, name: 'Food Delivery' },
  { icon: SailingIcon, name: 'Sailing' }
];

export default avatarIcons;