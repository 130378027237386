import React from 'react';
import { Box, Stack, Typography, Paper } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';

const ComingSoonMessage = () => {
  return (
    <Paper sx={{ mb: "1em" }}>
      <Stack direction="row">
        <ConstructionIcon sx={{ color: "red" }} />
        <Typography sx={{ ml: "1em", color: "red" }}>
          Coming soon
        </Typography>
      </Stack>
    </Paper>
  );
};

export default ComingSoonMessage;