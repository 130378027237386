import typingTipsArr from '../assets/typingTips.json';

import React, { useMemo } from 'react';
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles';

function TypingTip() {
  const theme = useTheme();

  const getRandomText = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * typingTipsArr.length);
    return typingTipsArr[randomIndex];
  },[]
);

  return (
    <Box sx={{
      typography: 'p',
      textAlign: 'center',
      bgcolor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText, 
      // borderRadius: 20,
      // m: '1em'
    }}>{getRandomText}</Box>
  );
}

export { TypingTip };
