import React from 'react';
import { Box, Typography } from '@mui/material';
import { privacyPolicy, termsOfService } from '../utils/privacyPolicyAndTermsOfServiceText.js';

  function PrivacyPolicyTermsOfServicePage() {
    return (
      <Box
        sx={{ width: "50%", m: "1em" }}>
        <Typography variant="h6">Privacy Policy</Typography>
        <Typography variant="body2" paragraph>
          {privacyPolicy}
        </Typography>
        <Typography variant="h6" style={{ marginTop: '1rem' }}>Terms of Service</Typography>
        <Typography variant="body2">
          {termsOfService}
        </Typography>
      </Box>
    );
  }

  export { PrivacyPolicyTermsOfServicePage };