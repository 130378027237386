import React, { useState,  useContext } from 'react';
import { Container, Typography, Stack } from '@mui/material'

import { FormControlLabel, Switch } from '@mui/material';
import { MainContentContext } from '../contexts/MainContentContext';

const TypingTestResults = ({ onToggleSwitch }) => {
  const { WPM, accuracy } = useContext(MainContentContext);
  // const [ switchState, setSwitchState ] = useState(true);

  // const handleChange = (event) => {
  //   setSwitchState(event.target.checked);
  //   onToggleSwitch(event.target.checked);
  // };

  return (
    <Container>
      <Stack direction="row"
        spacing={6}
        sx={{ display: 'flex', alignItems: 'center', }}
        data-tour="typing-test-results">
        <Typography variant="h6">WPM:{WPM}</Typography>
        <Typography variant="h6">Accuracy:{accuracy}%</Typography>
        {/* <Typography variant="h6">Test Duration: 60s</Typography> */}
        {/* <FormControlLabel
          control={<Switch color="secondary" checked={switchState} onChange={handleChange} />}
          label="Focus Mode"
        /> */}
      </Stack>
    </Container>
  );

}

export { TypingTestResults };