import React from "react";
import { List, ListItem, ListItemText } from "@mui/material";

const RoadMap = () => {
  return (
    <List>
      <ListItem>
        <ListItemText primary="Implement backend to save user profiles" />
      </ListItem>
      <ListItem>
        <ListItemText primary="Implement backend to save user statistics" />
      </ListItem>
      <ListItem>
        <ListItemText primary="adsense to support hosting services" />
      </ListItem>
      <ListItem>
        <ListItemText primary="premium features" />
      </ListItem>
    </List>
  );
};

export default RoadMap;