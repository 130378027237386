import { lighten } from '@mui/material/styles';
import palettes from '../themes/palettes.js';

// Common settings for all themes
const createCommonSettings = (selectedPalette, selectedFont) => {
  //check if palette exists, otherwise use the first one
  if (!palettes[selectedPalette]) {
    selectedPalette = Object.keys(palettes)[0];
  }
  const palette = palettes[selectedPalette].palette;
  const isDarkMode = palette.mode === 'dark';
  const lightPrimary = palette.primary.light || lighten(palette.primary.main, 0.2);

  return {
    zIndex: {
      snackbar: 1500,
      tooltip: 1300
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            overflowY: 'scroll',
          },
          '@media (min-width: 1920px)': {
            html: {
              fontSize: '14px', // Reduce base font size for large screens
            },
          },
        },
      },
      MuiAppBar: {
        //without this, the buttons in the appbar look weird
        //because the appbar has a weird arbitrary color
        styleOverrides: {
          colorPrimary: {
            backgroundImage: 'none',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '0.875rem',
            backgroundColor: palette.background.default,
            color: isDarkMode ? palette.primary.contrastText : palette.text.primary,
            border: `1px solid ${palette.divider}`,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: palette.text.primary,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: palette.text.primary,
          },
        },
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
      fontFamily: `${selectedFont}, Courier Prime, Inconsolata, Arial, sans-serif`,
    },
    customStyles: {
      patternsBox: {
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '10px',
        // The backgroundColor will be set in the theme creation
      },
    },
  };
};

export default createCommonSettings;