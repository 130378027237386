import React, {useContext} from 'react';
import { Typography, Box } from '@mui/material'
// import { AuthButton } from './AuthButtonAuth0';
import { AuthButton } from './AuthButtonFirebase';

  const text = "By signing in, I acknowledge that I have been granted early access to 'Typecelerate' [Working title], a typing training application.\nI understand that this is a work in progress and agree to keep all information about the website, including its features, functionality, and design, confidential.\nI will not share access to the website, screenshots, or detailed information about its contents with any third parties.\nThis agreement is based on mutual trust and good faith, intended to protect the innovative aspects of the project during its development phase.";

  const textWithBreaks = text.split('\n').map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));

function NonDisclosureAgreement() {

  return (
    <Box sx={{ m: '1em' }}>
      <Typography variant="h6">Non-Disclosure Agreement</Typography>
      <Typography variant="body1">
        {textWithBreaks}
      </Typography>
      <br />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <AuthButton />
      </Box>
    </Box>
  )
}

export default NonDisclosureAgreement;