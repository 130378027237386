import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AppProvider } from './contexts/AppContext'

// import { Amplify } from 'aws-amplify';
// import { Auth0Provider } from '@auth0/auth0-react';

// change concole.log to not do anything
// console.log = function() {}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>
);


    // <Auth0Provider
    //   domain="dev-2wfsb47jl1geix3y.us.auth0.com"
    //   clientId="iGJBCjFoIaRSMFglL4fpNyWzcfs4FqRj"
    //   authorizationParams={{
    //     redirect_uri: window.location.origin
    //   }}
    // >
    // </Auth0Provider>