import React from 'react';
import { SvgIcon } from '@mui/material';
import { ReactComponent as MascotSvg } from '../assets/mascot7.svg';

const MascotIcon = (props) => {

  return (
    <SvgIcon 
      component={MascotSvg} 
      inheritViewBox 
      {...props}
      sx={{ 
        width: '2.5em', 
        height: '2.5em', 
        m: 0, 
        p: 0,
        ...props.sx
      }}
    />
  );
};

export default MascotIcon ;