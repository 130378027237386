import { Box, Stack, Tooltip, Typography, Slider } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import InfoIcon from '@mui/icons-material/Info';

//I might still use this one
// const SliderWrapper2 = ({ label, tooltipTitle, value, onChange, min, max }) => {
//   const mid = (min + max) / 2;
//   return (
//     <Stack sx={{ width: "100%" }}>
//       <Grid container spacing={2} alignItems="center" justifyContent="center"
//         sx={{ width: "100%", m: "0" }}>
//         <Grid xs={10}>
//           <Typography>{label}</Typography>
//         </Grid>
//         <Grid xs={2}>
//           <Box sx={{ padding: 0, textAlign: "right", width: "100%" }}>
//             <Tooltip title={tooltipTitle}>
//               <ContactSupportIcon />
//             </Tooltip>
//           </Box>
//         </Grid>
//       </Grid>
//       <Slider
//         value={value}
//         onChange={onChange}
//         step={1}
//         marks={[
//           { value: min, label: min.toString() },
//           { value: mid, label: mid.toString() },
//           { value: max, label: max.toString() }
//         ]}
//         min={min}
//         max={max}
//         sx={{ width: "80%", mt: 0, ml: "1em" }}
//       />
//     </Stack>
//   );
// };

const SliderWrapper = ({ label, tooltipTitle, children, tooltipPlacement }) => {
  return (
    <Stack sx={{ width: "100%" }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center"
        sx={{ width: "100%", m: "0" }}>
        <Grid xs={10}>
          <Typography>{label}</Typography>
        </Grid>
        <Grid xs={2}>
          <Box sx={{ padding: 0, textAlign: "right", width: "100%" }}>
            <Tooltip title={tooltipTitle}
              placement={tooltipPlacement}
            >
              <InfoIcon />
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      {children}
    </Stack>
  );
};

export {SliderWrapper /*, SliderWrapper2*/};