// import { getText } from '../utils/textGenerator';

//old: single test analysis -> pattern aggregator -> pattern arbiter -> patterns packer -> history manager -> test generator
//new: single test analysis -> history manager -> pattern aggregator -> pattern arbiter -> patterns packer -> stateSetter & test generator
import { defaultLetterLimit } from '../utils/defaultValues.js';

const delay = (ms) => {
  const start = Date.now();
  while (Date.now() - start < ms) {
    // Delaying in a busy-wait loop
  }
}

class Metadata {
  constructor() {
    this.originalText = '';
    this.userText = '';
    this.errorIndices = [];
    this.startTime = 0;
    this.timeToType = [];
  }
}

class DesiredWeaknessPatterns {
  constructor() {
    this.slowLetters = true;
    this.slowBigrams = true;
    this.slowTrigrams = true;
    this.slowWords = true;
    this.errorneousLetters = true;
    this.errorneousBigrams = true;
    this.errorneousTrigrams = true;
    this.errorneousWords = true;
  }
}

class PackedPatterns {
  constructor() {
    this.errorArr = [];
    this.slowArr = [];
  }

  clone() {
    const copy = new PackedPatterns();
    copy.errorArr = [...this.errorArr];
    copy.slowArr = [...this.slowArr];
    return copy;
  }
}

class ErrorPattern {
  constructor(pattern) {
    this.pattern = pattern;
    this.errorCountInTest = 0;
    this.appearanceCountInText = 0;
    this.errorRate = 0;
  }

  incrErrorCount() {
    this.errorCountInTest++;
  }

  setAppearanceCount(numAppearances) {
    this.appearanceCountInText = numAppearances;
  }

  calculateErrorRate() {
    this.errorRate = this.errorCountInTest / this.appearanceCountInText;
  }
}

class TimePattern {
  constructor(pattern, time) {
    this.pattern = pattern;
    this.time = time;
    this.wpm = this.calculateWPM(pattern, time);
  }

  calculateWPM(pattern, time) {
    const numChars = pattern.length;
    const minutes = time / 60000;
    const words = numChars / 5;
    return Math.floor(words / minutes);
  }
}

class PatternWeakenssesAggregator {
  constructor(letterErrorPatternsArr, bigramErrorPatternsArr, trigramErrorPatternsArr, wordErrorPatternsArr,
    letterTimePatternsArr, bigramTimePatternsArr, trigramTimePatternsArr, wordTimePatternsArr) {
    //console.log("PatternsArrWeakenssesAggregator: constructor");
    this.letterErrorPatternsArr = letterErrorPatternsArr
    this.bigramErrorPatternsArr = bigramErrorPatternsArr
    this.trigramErrorPatternsArr = trigramErrorPatternsArr
    this.wordErrorPatternsArr = wordErrorPatternsArr
    this.letterTimePatternsArr = letterTimePatternsArr
    this.bigramTimePatternsArr = bigramTimePatternsArr
    this.trigramTimePatternsArr = trigramTimePatternsArr
    this.wordTimePatternsArr = wordTimePatternsArr
    //console.log(JSON.parse(JSON.stringify(this)));
  }
}

class SingleTestAnalysis {

  constructor(isFullyRandom, setTestStatus) {
    //input
    this.testMetaData = new Metadata();

    //outputs
    this.letterTTTarr = [];
    this.bigramTTTarr = [];
    this.trigramTTTarr = [];
    this.wordTTTarr = [];

    this.letterErrorArr = []
    this.bigramErrorArr = [];
    this.trigramErrorArr = [];
    this.wordErrorArr = [];

    this.WPM = 0;
    // this.setSlowPatterns = null;
    // this.setInaccuratePatterns = null;
    this.slowPatterns = null;
    this.inaccuratePatterns = null;
    this.desiredWeaknessPatterns = null;
    this.desiredWeaknessHistory = 3;
    this.desiredWordsHistory = 3;

    //intermediate helpers
    this.historyManager = new HistoryManager();
    this.testGenerator = new TestGenerator();
    this.patternStateSetter = new PatternStateSetter();
    this.patternPacker = new PatternsPacker();
    this.userWantedPatterns = [];
    this.userUnwantedPatterns = [];
    this.isFullyRandom = isFullyRandom;
    this.setTestStatus = setTestStatus;
  }

  clearHistory() {
    this.historyManager = new HistoryManager();
  }

  setUserRequestedPatterns(includedPatterns, excludedPatterns) {
    this.userWantedPatterns = includedPatterns;
    this.userUnwantedPatterns = excludedPatterns;
  }

  getPerformanceMetrics(testMetaData, desiredWeaknessPatterns, desiredWeaknessHistory,
    setSlowPatterns, setInaccuratePatterns, slowPatterns, inaccuratePatterns,
    includedPatterns, excludedPatterns, inaccuratePatternsNum, slowPatternsNum,
    freezeHistory, desiredWordsHistory) {
    // this.setSlowPatterns = setInaccuratePatterns;
    // this.setInaccuratePatterns = setSlowPatterns;
    this.slowPatterns = slowPatterns;
    this.inaccuratePatterns = inaccuratePatterns;
    this.setUserRequestedPatterns(includedPatterns, excludedPatterns);
    //console.log("desiredHistory = " + desiredHistory)
    this.desiredWeaknessHistory = desiredWeaknessHistory;
    this.desiredWordsHistory = desiredWordsHistory;
    this.setTestMetaData(testMetaData);
    this.desiredWeaknessPatterns = desiredWeaknessPatterns;
    //console.log(JSON.parse(JSON.stringify(this.desiredWeaknessPatterns)));
    // populate the weakness arrays in a special format to help the PatternArbiter
    //These 2 functions are super duper important, and badly named.
    const WPM = this.getLatestWPM();
    const accuracy = this.getLatestAccuracy();
    if (!freezeHistory) {
      // Aggregate the ararys to a single object to pass to HistoryManager
      // Only the arrays that the user wanted will have content. The rest will stay empty.
      const currTestAggregatedPatterns = new PatternWeakenssesAggregator(
        this.letterErrorArr,
        this.bigramErrorArr,
        this.trigramErrorArr,
        this.wordErrorArr,
        this.letterTTTarr,
        this.bigramTTTarr,
        this.trigramTTTarr,
        this.wordTTTarr
      );

      this.historyManager.setDesiredWeaknessHistory(desiredWeaknessHistory);
      //Give the history manager information about the latest test.
      this.historyManager.addTestAggregatedPatterns(currTestAggregatedPatterns)
      //The history manager will purge any information that is older than the desired history.
      this.historyManager.removeOldPatterns();
      //The patternArbiter will decide which patterns to use based on the patterns the user is worst at.
      //No priority is given to newer results. The worst patterns are always used.
      const patternArbiter = new PatternArbiter();
      patternArbiter.setAggregatedPatterns(this.historyManager.getLatestCombinedAggregatedWeaknesses());
      patternArbiter.setDesiredInaccuratePatternsNum(inaccuratePatternsNum);
      patternArbiter.setDesiredSlowPatternsNum(slowPatternsNum);
      patternArbiter.arbitratePatterns();

      this.patternPacker.setArbitratedSlowPatterns(patternArbiter.getArbitratedSlowPatterns());
      this.patternPacker.setArbitratedInaccuratePatterns(patternArbiter.getArbitratedInaccuratePatterns());
      this.patternPacker.setDesiredWeaknessHistory(desiredWeaknessHistory);
      this.patternPacker.setUIslowPatterns(slowPatterns);
      this.patternPacker.setUIinaccuratePatterns(inaccuratePatterns);
      this.patternPacker.packPatterns();
      this.patternPacker.manipulateHistoryBasedOnUserAdditionsAndDeletionsFromUI();

      this.patternStateSetter.setPackedPatterns(this.patternPacker.getPackedPatterns());
      this.patternStateSetter.setSlowPatternsSetter(setSlowPatterns);
      this.patternStateSetter.setInaccuratePatternsSetter(setInaccuratePatterns);
      this.patternStateSetter.setPatternsBoxes();
    }
    return { WPM, accuracy };
  }

  setTestMetaData(testMetaData) {
    //console.log("setTestMetaData")
    if (testMetaData.userText.length !== testMetaData.originalText.length)
      throw new Error("userText to originalText length mismatch")
    if (testMetaData.timeToType.length !== testMetaData.originalText.length)
      throw new Error("timeToType to originalText length mismatch")
    this.testMetaData = testMetaData;
    //console.log(JSON.parse(JSON.stringify(testMetaData)))
  };

  getLatestWPM() {
    this.clearTimeArrays();
    const originalText = this.testMetaData.originalText;
    const userText = this.testMetaData.userText;
    const textLen = originalText.length;
    const originalTextArray = Array.from(originalText);
    const correctLetters = originalTextArray.filter((char, index) => char === userText[index]);
    const correctLetterCount = correctLetters.length;
    const timeToTypeSeconds = this.testMetaData.timeToType[textLen - 1] / 1000;
    const CharactersPerSecond = correctLetterCount / timeToTypeSeconds;
    const secondsInMinute = 60;
    const CharactersPerMinute = secondsInMinute * CharactersPerSecond;
    const wordLen = 5;
    this.WPM = Math.floor(CharactersPerMinute / wordLen);
    if (!(this.WPM !== Infinity && this.WPM !== null && this.WPM >= 0)) throw new Error("WPM calculation is invalid")

    // console.log(
    //   `
    //     Original Text: ${originalText}
    //     Original Text Length: ${textLen}
    //     User Text: ${userText}
    //     Length of Text Written Correctly: ${correctLetterCount}
    //     Time to Type (in seconds): ${timeToTypeSeconds}
    //     Average Word Length: ${wordLen}
    //     Characters Per Minute (CPM): ${CharactersPerMinute}
    //     Words Per Minute (WPM): ${this.WPM}
    //   `);
    //console.log(JSON.parse(JSON.stringify(this.desiredWeaknessPatterns)));
    if (this.desiredWeaknessPatterns.slowLetters)
      this.populateTimeLetters();
    if (this.desiredWeaknessPatterns.slowBigrams)
      this.populateTimeBigrams();
    if (this.desiredWeaknessPatterns.slowTrigrams)
      this.populateTimeTrigrams();
    if (this.desiredWeaknessPatterns.slowWords)
      this.populateTimeWords();
    return this.WPM;
  };

  getLatestAccuracy() {
    this.clearErrorArrays();
    const originalText = this.testMetaData.originalText;
    const textLen = originalText.length;
    const originalTextArray = Array.from(originalText);
    const userText = this.testMetaData.userText;
    const userTextArray = Array.from(userText);
    const correctLetters = originalTextArray.filter((char, index) => char === userTextArray[index]);
    const correctLetterCount = correctLetters.length;
    const Accuracy = Math.floor(100 * correctLetterCount / textLen);
    if (this.desiredWeaknessPatterns.errorneousLetters ||
      this.desiredWeaknessPatterns.errorneousBigrams ||
      this.desiredWeaknessPatterns.errorneousTrigrams ||
      this.desiredWeaknessPatterns.errorneousWords
    ) {
      this.populateErrorneousPatterns();
    }
    return Accuracy;
  };

  // asyncGetNextTest(someInteger) {
  //   return new Promise((resolve, reject) => {
  //     resolve(this.getNextTest(someInteger));
  //   });
  // }

  getNextTest(wordCount, wordPool, desiredRandomness = 0,
    removeOutputsFromPool = true, setAlgorithmFoundWords, letterLimit) {
    // delay(3000);
    const wantedPatterns = this.patternPacker.getReallyPackedPatterns();
    if (desiredRandomness === 100) {
      letterLimit = defaultLetterLimit;
    }
    this.isFullyRandom.current =
      (
        (
          (desiredRandomness === 100 && this.userUnwantedPatterns.length === 0) ||
          (wantedPatterns.length === 0 && this.userWantedPatterns.length === 0 && this.userUnwantedPatterns.length === 0)
        )
      );

    this.testGenerator.setWordPool(wordPool);
    //concat the history flat array with this.userWantedPatterns
    this.testGenerator.setWantedPatterns(wantedPatterns);
    this.testGenerator.setVIPwantedPatterns(this.userWantedPatterns);
    this.testGenerator.setUnwantedPatterns(this.userUnwantedPatterns);
    this.testGenerator.setNumWordInTest(wordCount);
    this.testGenerator.setMaxWordLength(letterLimit);
    this.testGenerator.setDesiredWordsHistory(this.desiredWordsHistory);
    this.testGenerator.setDesiredRandomness(desiredRandomness);
    this.testGenerator.setRemoveOutputsFromPool(removeOutputsFromPool);
    this.testGenerator.setSetAlgorithmFoundWords(setAlgorithmFoundWords);
    this.testGenerator.setSetTestStatus(this.setTestStatus);
    const test = this.testGenerator.getTest();
    return test;
  }

  addTimePatternToArr(arr, text, timeToType) {
    // //console.log(text)
    arr.push(new TimePattern(text, timeToType));
  }

  addErrorPattern(errorPatternArr, text) {
    //console.log("addErrorPattern: text = " + text)
    let foundText = 0;
    //First we search if the pattern is already in the array
    errorPatternArr.forEach(element => {
      if (element.pattern === text) {
        element.incrErrorCount();
        foundText = 1;
      }
    });
    if (foundText) {
      return;
    }
    //If the pattern is not in the array, we add it
    let newErrorPattern = new ErrorPattern(text);
    newErrorPattern.incrErrorCount();
    errorPatternArr.push(newErrorPattern);
  }

  clearTimeArrays() {
    this.letterTTTarr = [];
    this.bigramTTTarr = [];
    this.trigramTTTarr = [];
    this.wordTTTarr = [];
  }

  clearErrorArrays() {
    this.letterErrorArr = [];
    this.bigramErrorArr = [];
    this.trigramErrorArr = [];
    this.wordErrorArr = [];
  }

  removeConsecutiveIndices(indices) {
    if (indices.length === 0) return [];

    const result = [indices[0]];

    for (let i = 1; i < indices.length; i++) {
      if (indices[i] !== indices[i - 1] + 1) {
        result.push(indices[i]);
      }
    }

    return result;
  }

  populateErrorneousPatterns() {
    //console.log("populateErrorneousLetters")
    //console.log("originalText: " + this.testMetaData.originalText)
    //console.log("error indices: " + this.testMetaData.errorIndices)
    //remove indexes from the this.errorIndices array if they come immediately after another error index
    //for example if the indexes are [1,2,3,6,7,8,10] then it should be [1,6,10]
    //This is to avoid trailing errors by the user
    this.testMetaData.errorIndices = this.removeConsecutiveIndices(this.testMetaData.errorIndices);
    //console.log("error indices: " + this.testMetaData.errorIndices)
    const originalTextArr = Array.from(this.testMetaData.originalText);
    const errorIndicesArray = this.testMetaData.errorIndices || [];
    const textLen = originalTextArr.length;
    //console.log("errorIndicesArray: " + errorIndicesArray)
    //console.log(JSON.parse(JSON.stringify(errorIndicesArray)));

    errorIndicesArray.forEach(element => {
      //single letters and bigrams
      if (originalTextArr[element] !== ' ') {
        //letter
        if (this.desiredWeaknessPatterns.errorneousLetters)
          this.addErrorPattern(this.letterErrorArr, originalTextArr[element])
        //Bigram
        if (this.desiredWeaknessPatterns.errorneousBigrams) {
          if (element !== 0) {
            if (originalTextArr[element - 1] !== ' ') {
              this.addErrorPattern(this.bigramErrorArr, originalTextArr[element - 1] + originalTextArr[element])
            }
          }
          if (element !== textLen - 1) {
            if (originalTextArr[element + 1] !== ' ') {
              this.addErrorPattern(this.bigramErrorArr, originalTextArr[element] + originalTextArr[element + 1])
            }
          }
        }
      }
      //Trigrams - Claude wrote this
      if (this.desiredWeaknessPatterns.errorneousTrigrams) {
        if (textLen > 2) {
          const start = Math.max(0, element - 2);
          const end = Math.min(textLen - 1, element + 2);
          const slicedArr = originalTextArr.slice(start, end + 1);

          for (let i = 0; i < slicedArr.length - 2; i++) {
            if (slicedArr[i] !== ' ' && slicedArr[i + 1] !== ' ' && slicedArr[i + 2] !== ' ') {
              this.addErrorPattern(this.trigramErrorArr, slicedArr[i] + slicedArr[i + 1] + slicedArr[i + 2]);
            }
          }
        }
      }
      // Word errors - claude wrote this
      if (this.desiredWeaknessPatterns.errorneousWords) {
        if (/\w/.test(originalTextArr[element])) {
          let start = element;
          let end = element;
          while (start > 0 && /\w/.test(originalTextArr[start - 1])) {
            start--;
          }
          while (end < textLen - 1 && /\w/.test(originalTextArr[end + 1])) {
            end++;
          }
          const word = originalTextArr.slice(start, end + 1).join('');
          this.addErrorPattern(this.wordErrorArr, word);
        }
      }
    });
    //Finished counting each pattern error
    //Now we count the number of appearances of each pattern in the text


    const updateErrorPatterns = (patternsArray, originalTextArr) => {
      patternsArray.forEach(element => {
        element.setAppearanceCount(this.countPatternAppearances(element.pattern, originalTextArr));
        element.calculateErrorRate();
      });
    }

    updateErrorPatterns(this.letterErrorArr, originalTextArr);
    updateErrorPatterns(this.bigramErrorArr, originalTextArr);
    updateErrorPatterns(this.trigramErrorArr, originalTextArr);
    updateErrorPatterns(this.wordErrorArr, originalTextArr);


    // this.letterErrorArr = []
    // this.bigramErrorArr = [];
    // this.trigramErrorArr = [];
    // this.wordErrorArr = [];
    // originalTextArr

    //console.log(JSON.parse(JSON.stringify(this.letterErrorArr)));
    //console.log(JSON.parse(JSON.stringify(this.bigramErrorArr)));
    //console.log(JSON.parse(JSON.stringify(this.trigramErrorArr)));
    //console.log(JSON.parse(JSON.stringify(this.wordErrorArr)));
  }

  countPatternAppearances(pattern, textArr) {
    let count = 0;
    for (let i = 0; i < textArr.length; i++) {
      if (textArr.slice(i, i + pattern.length).join('') === pattern) {
        count++;
      }
    }
    return count;
  }

  populateTimeLetters() {
    //console.log("populateTimeLetters")
    const originalText = this.testMetaData.originalText;
    const errorIndices = new Set(this.testMetaData.errorIndices);
    const timeArr = this.testMetaData.timeToType;
    // Iterate through the timeArr to calculate the differences
    for (let i = 1; i < timeArr.length - 4; i++) {
      if (errorIndices.has(i) || originalText[i] === ' ') {
        continue;
      }
      const timeDiff = timeArr[i] - timeArr[i - 1];
      const letter = originalText[i];
      // //console.log("i = " + i + ", letter = " + letter)
      this.addTimePatternToArr(this.letterTTTarr, letter, timeDiff);
    }
    //console.log(JSON.parse(JSON.stringify(this.letterTTTarr)));
  }

  populateTimeBigrams() {
    //console.log("populateTimeBigrams")
    const originalText = this.testMetaData.originalText;
    const errorIndices = new Set(this.testMetaData.errorIndices);
    const timeArr = this.testMetaData.timeToType;
    // Iterate through userText to find valid bigrams
    for (let i = 2; i < originalText.length; i++) {
      // Ignore indices that are marked as errors
      if (errorIndices.has(i) || errorIndices.has(i - 1)) {
        continue;
      }
      //Time it takes to type the 2 letters
      const timeDiff = timeArr[i] - timeArr[i - 2];
      const bigram = originalText[i - 1] + originalText[i];
      if (bigram.indexOf(" ") === -1) {
        this.addTimePatternToArr(this.bigramTTTarr, bigram, timeDiff);
      };
    }
    //console.log(JSON.parse(JSON.stringify(this.bigramTTTarr)));
  }

  populateTimeTrigrams() {
    //console.log("populateTimeTrigrams")
    const originalText = this.testMetaData.originalText;
    const errorIndices = new Set(this.testMetaData.errorIndices);
    const timeArr = this.testMetaData.timeToType;
    // Iterate through userText to find valid trigrams
    for (let i = 3; i < originalText.length; i++) {
      // Ignore indices that are marked as errors
      if (errorIndices.has(i - 2) || errorIndices.has(i) || errorIndices.has(i - 1)) {
        continue;
      }
      //Time it takes to type the 3 letters
      const timeDiff = timeArr[i] - timeArr[i - 3];
      const trigram = originalText[i - 2] + originalText[i - 1] + originalText[i];
      if (trigram.indexOf(" ") === -1) {
        this.addTimePatternToArr(this.trigramTTTarr, trigram, timeDiff);
      }
    }
    //console.log(JSON.parse(JSON.stringify(this.trigramTTTarr)));
  }

  populateTimeWords() {
    //console.log("populateTimeWords")
    const originalText = this.testMetaData.originalText;
    const errorIndices = new Set(this.testMetaData.errorIndices);
    const timeArr = this.testMetaData.timeToType;
    const endIndex = originalText.length - 1;
    let preSpaceIdx = -1; // Initialize preSpaceIdx to -1 outside the loop
    for (let i = 0; i <= endIndex; i++) {
      if (originalText[i] === ' ' && preSpaceIdx === -1) {
        // Found the first space
        preSpaceIdx = i;
      } else if (originalText[i] === ' ' && preSpaceIdx !== -1) {
        // Found the second space
        const postSpaceIdx = i;
        const timeDiff = timeArr[postSpaceIdx - 1] - timeArr[preSpaceIdx];
        const word = originalText.slice(preSpaceIdx + 1, postSpaceIdx);
        //If the word contains an error, then the timing of it is irrelevant
        if (!this.wordContainsError(preSpaceIdx + 1, postSpaceIdx - 1, errorIndices)) {
          this.addTimePatternToArr(this.wordTTTarr, word, timeDiff);
        }
        preSpaceIdx = i; // Update preSpaceIdx for next word
      } else if (i === endIndex && preSpaceIdx !== -1) {
        // Handle the last word (no space after it)
        const postSpaceIdx = endIndex + 1; // Simulate a space after the last character
        const timeDiff = timeArr[postSpaceIdx - 1] - timeArr[preSpaceIdx];
        const word = originalText.slice(preSpaceIdx + 1); // Slice from preSpaceIdx+1 to the end
        //If the word contains an error, then the timing of it is irrelevant
        if (!this.wordContainsError(preSpaceIdx + 1, endIndex, errorIndices)) {
          this.addTimePatternToArr(this.wordTTTarr, word, timeDiff);
        }
      }
    }
    //console.log(JSON.parse(JSON.stringify(this.wordTTTarr)));
  }

  wordContainsError(start, end, errorIndices) {
    for (let i = start; i < end; i++) {
      if (errorIndices.has(i)) {
        return true;
      }
    }
    return false;
  }

};

// Responsible for  the selection of which weaknesses to use.
class PatternArbiter {
  constructor() {
    this.inputPatternWeakenssesAggregator = [];
    this.desiredInaccuratePatternsNum = 0;
    this.desiredSlowPatternsNum = 0;
    this.combinedSlowPatterns = [];
    this.combinedInaccuratePatterns = [];
  };

  setAggregatedPatterns(patternWeakenssesAggregator) {
    this.inputPatternWeakenssesAggregator = patternWeakenssesAggregator;
  };

  setDesiredInaccuratePatternsNum(inaccuratePatternsNum) {
    this.desiredInaccuratePatternsNum = inaccuratePatternsNum;
  };

  setDesiredSlowPatternsNum(slowPatternsNum) {
    this.desiredSlowPatternsNum = slowPatternsNum;
  };

  getArbitratedSlowPatterns() {
    return this.combinedSlowPatterns;
  }

  getArbitratedInaccuratePatterns() {
    return this.combinedInaccuratePatterns;
  }

  combineSlowPatterns() {
    if (this.inputPatternWeakenssesAggregator === null) {
      return;
    }
    this.combinedSlowPatterns = this.inputPatternWeakenssesAggregator.letterTimePatternsArr.concat(
      this.inputPatternWeakenssesAggregator.bigramTimePatternsArr,
      this.inputPatternWeakenssesAggregator.trigramTimePatternsArr,
      this.inputPatternWeakenssesAggregator.wordTimePatternsArr
    );
  }

  combineInaccuratePatterns() {
    if (this.inputPatternWeakenssesAggregator === null) {
      return;
    }
    this.combinedInaccuratePatterns = this.inputPatternWeakenssesAggregator.letterErrorPatternsArr.concat(
      this.inputPatternWeakenssesAggregator.bigramErrorPatternsArr,
      this.inputPatternWeakenssesAggregator.trigramErrorPatternsArr,
      this.inputPatternWeakenssesAggregator.wordErrorPatternsArr
    );
  }

  sortSlowPatterns() {
    this.combinedSlowPatterns.sort((a, b) => a.wpm - b.wpm);
    const uniquePatternsMap = new Map();
    this.combinedSlowPatterns.forEach(pattern => {
      if (!uniquePatternsMap.has(pattern.pattern)) {
        uniquePatternsMap.set(pattern.pattern, pattern);
      }
    });
    this.combinedSlowPatterns = Array.from(uniquePatternsMap.values());
  }

  removefastPatterns() {
    this.combinedSlowPatterns = this.combinedSlowPatterns.slice(0, this.desiredSlowPatternsNum);
  }

  // Claude wrote this
  sortInaccuratePatterns() {
    // Create a Map to store unique patterns
    const uniquePatterns = new Map();

    // Combine duplicate patterns
    for (const pattern of this.combinedInaccuratePatterns) {
      if (uniquePatterns.has(pattern.pattern)) {
        // If pattern already exists, update its counts
        const existingPattern = uniquePatterns.get(pattern.pattern);
        existingPattern.errorCountInTest += pattern.errorCountInTest;
        existingPattern.appearanceCountInText += pattern.appearanceCountInText;
      } else {
        // If it's a new pattern, add it to the Map
        uniquePatterns.set(pattern.pattern, pattern);
      }
    }

    // Recalculate error rates for all patterns
    for (const pattern of uniquePatterns.values()) {
      pattern.calculateErrorRate();
    }

    // Convert Map values back to an array and sort
    this.combinedInaccuratePatterns = Array.from(uniquePatterns.values())
      .sort((a, b) => b.errorRate - a.errorRate);
  }

  // sortInaccuratePatterns() {
  //   this.combinedInaccuratePatterns.sort((a, b) => b.errorRate - a.errorRate);
  // }


  removeNotSoInaccuratePatterns() {
    this.combinedInaccuratePatterns = this.combinedInaccuratePatterns.slice(0, this.desiredInaccuratePatternsNum);
  }

  arbitratePatterns() {
    this.combineSlowPatterns();
    this.combineInaccuratePatterns();
    this.sortSlowPatterns();
    this.sortInaccuratePatterns();
    this.removefastPatterns();
    this.removeNotSoInaccuratePatterns();
  }

}


//This class takes the weakness arrays with their weird little "count" and "WPM" and turns
//them to a simple array of text elements.
//Because this class basically determines the output to the UI, it can hold a history
//of the user's weaknesses and strengths, giving us something to compare with the actual UI
//boxes, and thus we can know what the user added or deleted.
//With this knowledge we can make the output of this class take that into account.
//BUT IT IS VERY ANNOYING.
class PatternsPacker {
  constructor() {
    this.currPackedPatterns = new PackedPatterns();
    this.lastCurrPackedPatterns = new PackedPatterns();
    this.desiredWeaknessHistory = 0;
    this.arbitratedSlowPatterns = [];
    this.arbitratedInaccuratePatterns = [];
    this.UIslowPatterns = [];
    this.UIinaccuratePatterns = [];
    this.packedPatterns = [];
    this.userAddedSlowPatterns = [];
    this.userAddedErrorPatterns = [];
    this.userDeletedSlowPatterns = [];
    this.userDeletedErrorPatterns = [];

    this.userAddedSlowPatternsWithHistory = [];
    this.userAddedErrorPatternsWithHistory = [];
    this.userDeletedSlowPatternsWithHistory = [];
    this.userDeletedErrorPatternsWithHistory = [];
  }

  setDesiredWeaknessHistory(desiredWeaknessHistory) {
    this.desiredWeaknessHistory = desiredWeaknessHistory;
  }

  setArbitratedSlowPatterns(arbitratedSlowPatterns) {
    this.arbitratedSlowPatterns = arbitratedSlowPatterns;
  }
  setArbitratedInaccuratePatterns(arbitratedInaccuratePatterns) {
    this.arbitratedInaccuratePatterns = arbitratedInaccuratePatterns;
  }

  setUIslowPatterns(slowPatterns) {
    this.UIslowPatterns = slowPatterns
  };

  setUIinaccuratePatterns(inaccuratePatterns) {
    this.UIinaccuratePatterns = inaccuratePatterns
  }

  collectUsersAdditionsAndDeletionsFromUI() {
    // A lot of code repititon but Claude's improvements were alien so I'm keeping it like this.
    // debugger;
    this.userAddedSlowPatterns = this.UIslowPatterns.filter(pattern => {
      return !this.lastCurrPackedPatterns.slowArr.includes(pattern);
    });
    //console.log("this.userAddedSlowPatterns: " + this.userAddedSlowPatterns)
    this.userAddedErrorPatterns = this.UIinaccuratePatterns.filter(pattern => {
      return !this.lastCurrPackedPatterns.errorArr.includes(pattern);
    });
    this.userDeletedSlowPatterns = this.lastCurrPackedPatterns.slowArr.filter(pattern => {
      return !this.UIslowPatterns.includes(pattern);
    });
    this.userDeletedErrorPatterns = this.lastCurrPackedPatterns.errorArr.filter(pattern => {
      return !this.UIinaccuratePatterns.includes(pattern);
    });

    this.userAddedErrorPatternsWithHistory.unshift(this.userAddedErrorPatterns);
    this.userAddedSlowPatternsWithHistory.unshift(this.userAddedSlowPatterns);
    this.userDeletedErrorPatternsWithHistory.unshift(this.userDeletedErrorPatterns);
    this.userDeletedSlowPatternsWithHistory.unshift(this.userDeletedSlowPatterns);
    if (this.userAddedErrorPatternsWithHistory.length > this.desiredWeaknessHistory) {
      this.userAddedErrorPatternsWithHistory.pop();
    }
    if (this.userAddedSlowPatternsWithHistory.length > this.desiredWeaknessHistory) {
      this.userAddedSlowPatternsWithHistory.pop();
    }
    if (this.userDeletedErrorPatternsWithHistory.length > this.desiredWeaknessHistory) {
      this.userDeletedErrorPatternsWithHistory.pop();
    }
    if (this.userDeletedSlowPatternsWithHistory.length > this.desiredWeaknessHistory) {
      this.userDeletedSlowPatternsWithHistory.pop();
    }

  }

  addUserAdditionsToCurrPackedPatterns() {
    this.currPackedPatterns.slowArr = this.currPackedPatterns.slowArr.concat(this.userAddedSlowPatternsWithHistory.flat());
    this.currPackedPatterns.errorArr = this.currPackedPatterns.errorArr.concat(this.userAddedErrorPatternsWithHistory.flat());
  }

  removeUserDeletionsFromCurrPackedPatterns() {
    this.currPackedPatterns.slowArr = this.currPackedPatterns.slowArr.filter(pattern => {
      return !this.userDeletedSlowPatternsWithHistory.flat().includes(pattern);
    });
    this.currPackedPatterns.errorArr = this.currPackedPatterns.errorArr.filter(pattern => {
      return !this.userDeletedErrorPatternsWithHistory.flat().includes(pattern);
    });
  }

  manipulateHistoryBasedOnUserAdditionsAndDeletionsFromUI() {
    //This function is called after the patterns are packed.
    //It takes the user's additions and deletions into account.
    this.collectUsersAdditionsAndDeletionsFromUI();
    //This is terrible and not working
    //The way I'm inferring what the user added or deleted is bad bad bad
    //Future nimw : what is this retarded comment? It's not helpful and as
    //far as I can tell, the function is working. Next time describe the 
    //symptoms, dummy.

    this.addUserAdditionsToCurrPackedPatterns();
    this.removeUserDeletionsFromCurrPackedPatterns();
    this.lastCurrPackedPatterns = this.currPackedPatterns.clone();
  }

  packPatterns() {
    //Take the patterns that were bestowed upon us by the PatternArbiter
    //and pack them into a simple array of text elements.
    this.currPackedPatterns.errorArr = this.arbitratedInaccuratePatterns.map(element => {
      return element.pattern;
    });
    this.currPackedPatterns.slowArr = this.arbitratedSlowPatterns.map(element => {
      return element.pattern;
    });
  }

  getPackedPatterns() {
    return this.currPackedPatterns;
  }

  getReallyPackedPatterns() {
    return this.currPackedPatterns.errorArr.concat(this.currPackedPatterns.slowArr);
  }
}


class HistoryManager {

  constructor() {
    this.desiredWeaknessHistory = 0;
    this.testsAggregatedPatternsArr = [];
  }

  setDesiredWeaknessHistory(desiredWeaknessHistory) {
    this.desiredWeaknessHistory = desiredWeaknessHistory;
  }

  addTestAggregatedPatterns(currTestAggregatedPatterns) {
    this.testsAggregatedPatternsArr.push(currTestAggregatedPatterns);
  }

  removeOldPatterns() {
    while (this.testsAggregatedPatternsArr.length > this.desiredWeaknessHistory) {
      this.testsAggregatedPatternsArr.shift();
    }
  }

  //Function name seems dumb, but let me explain.
  //Aggregated just means that a single object holds the 8 arrays.
  //Combined means that previously I had an array of objects, but now I have a single object.
  //The arrays inside that object are just concatenated arrays of the previous objects.
  getLatestCombinedAggregatedWeaknesses() {
    if (this.testsAggregatedPatternsArr.length === 0) {
      return null;
    }
    const result = new PatternWeakenssesAggregator([], [], [], [], [], [], [], []);
    for (const patterns of this.testsAggregatedPatternsArr) {
      for (const key in patterns) {
        if (Array.isArray(patterns[key])) {
          result[key] = result[key].concat(patterns[key]);
        }
      }
    }
    return result;
  };
}


class PatternStateSetter {
  constructor() {
    this.packedPatterns = [];
    this.slowPatterns = [];
    this.inaccuratePatterns = [];
    this.slowPatternsSetter = null;
    this.inaccuratePatternsSetter = null;
  }

  setPackedPatterns(packedPatterns) {
    this.packedPatterns = packedPatterns;
  }

  setSlowPatternsSetter(slowPatternsSetter) {
    this.slowPatternsSetter = slowPatternsSetter;
  }

  setInaccuratePatternsSetter(inaccuratePatternsSetter) {
    this.inaccuratePatternsSetter = inaccuratePatternsSetter;
  }

  setPatternsBoxes() {
    if (this.slowPatternsSetter) {
      this.slowPatternsSetter(this.packedPatterns.slowArr);
    }
    if (this.inaccuratePatternsSetter) {
      this.inaccuratePatternsSetter(this.packedPatterns.errorArr);
    }
  }
}


// This should get the wanted and unwanted patterns and select words from the word pool
// create an instance of this in the analysis class so react component TypingTest 
// can get the test from there.
//I think I want to create a base class and extend it for each language. - in the future
//for now I will do everything from scratch each invocation.
//1. get the word pool
//2. get the wanted and unwanted patterns
//3. remove unwanted patterns from the word pool
//4. set the weighted patterns
//5. select the words from the word pool using the algorithm from my previous project
//6. return the words

class TestGenerator {
  constructor() {
    this.wordPoolArr = [];
    // this.filteredWordPoolArr = [];
    this.numWordsInTest = 0;
    this.maxWordLength = 0;
    this.unwantedPatternsArr = [];
    this.wantedPatternsArr = [];
    //TODO for whatever reason, the VIP patterns algorithm does not try to bring words
    //with many letters. it just brings very short words with a single appearance of a VIP letter
    this.VIPwantedPatterns = [];
    this.unwantedPatternsArrChanged = true;
    this.weightedPatternsArr = [];
    this.unwantedWeightedPatternsArr = [];
    this.outputWordsList = [];
    this.previouslyOutputtedWords = []; //2D array
    this.desiredWordsHistory = 0;
    this.desiredRandomness = 0;
    this.desiredRandomWords = 0;
    this.removeOutputsFromPool = true;
    this.setAlgorithmFoundWords = () => { };
    this.successfullyFoundWords = false;
    this.setTestStatus = () => { };
    this.textTosetTestStatus = "";
  }

  setSetTestStatus(setTestStatus) {
    this.setTestStatus = setTestStatus;
  };

  setSetAlgorithmFoundWords(setAlgorithmFoundWords) {
    this.setAlgorithmFoundWords = setAlgorithmFoundWords;
  }

  setDesiredWordsHistory(desiredWordsHistory) {
    this.desiredWordsHistory = desiredWordsHistory;
  }

  setDesiredRandomness(desiredRandomness) {
    this.desiredRandomness = desiredRandomness;
    this.desiredRandomWords = Math.ceil(this.numWordsInTest * desiredRandomness / 100);
  }

  setRemoveOutputsFromPool(removeOutputsFromPool) {
    this.removeOutputsFromPool = removeOutputsFromPool;
  }

  setWordPool(wordPool) {
    this.wordPoolArr = [...wordPool];
    console.log("wordPool length = ", this.wordPoolArr.length)
  }

  setVIPwantedPatterns(VIPwantedPatterns) {
    this.VIPwantedPatterns = VIPwantedPatterns;
    //console.log("VIPwantedPatterns = ", this.VIPwantedPatterns)
  }

  setWantedPatterns(wantedPatternsArr) {
    this.wantedPatternsArr = wantedPatternsArr;
    //console.log("wantedPatternsArr = ", this.wantedPatternsArr)
  }

  setNumWordInTest(numWordInTest) {
    this.numWordsInTest = numWordInTest;
    console.log("numWordInTest = ", numWordInTest)
  }

  setMaxWordLength(letterLimit) {
    // this.wordPoolArr = this.wordPoolArr.filter(word => word.length <= letterLimit);
    this.maxWordLength = letterLimit;
  }

  setUnwantedPatterns(unwantedPatternsArr) {
    this.unwantedPatternsArr = unwantedPatternsArr;
  }

  prepareWeightedPatterns() {
    //console.log("prepareWeightedPatterns")
    if (this.wantedPatternsArr.length === 0) {
      //TODO cute idea but it makes the same words show up in the test
      //The idea is to get a good mix of all the letters
      // this.wantedPatternsArr = Array.from({ length: 26 }, (_, i) => String.fromCharCode(97 + i));
      //Perhaps I can take a random subset of the words and then apply this logic
      //and I won't get the same words over and over
    }
    this.weightedPatternsArr = this.wantedPatternsArr.map(pattern => {
      const currCountedPattern = new WeightedPattern();
      currCountedPattern.setPattern(pattern);
      return currCountedPattern;
    });

    this.unwantedWeightedPatternsArr = this.unwantedPatternsArr.map(pattern => {
      const currCountedPattern = new WeightedPattern();
      currCountedPattern.setPattern(pattern);
      currCountedPattern.setIsUnwatedPattern(true);
      return currCountedPattern;
    });
    this.weightedPatternsArr = this.weightedPatternsArr.concat(this.unwantedWeightedPatternsArr);

    const tmpVIPwantedWeightedPatternsArr = this.VIPwantedPatterns.map(pattern => {
      const currCountedPattern = new WeightedPattern();
      currCountedPattern.setPattern(pattern);
      currCountedPattern.setCustomPositiveWeight(5);
      return currCountedPattern;
    });
    this.weightedPatternsArr = this.weightedPatternsArr.concat(tmpVIPwantedWeightedPatternsArr);
    //console.log(JSON.parse(JSON.stringify(this.weightedPatternsArr)));
  }

  getTest() {
    this.successfullyFoundWords = true;
    this.outputWordsList = [];
    console.log("getTest")
    //TODO nimw I'm really confused why this code was here, why it's commented, and why there's no such function
    // this.excludeUnwantedPatternsFromWordPool();
    this.prepareWeightedPatterns();
    this.populateOutputWordList();
    console.log("outputWordsList.length = ", this.outputWordsList.length)
    console.log("numWordInTest = ", this.numWordsInTest)
    if (this.outputWordsList.length !== this.numWordsInTest) {
      console.error("There is a bug in test generation. Please try again.")
      return "We could not generate a test with the requested patterns. Please try again."
    }
    const test = this.outputWordsList.join(' ');
    this.setAlgorithmFoundWords(this.successfullyFoundWords);
    console.log("returning test = " + test + "successfullyFoundWords = " + this.successfullyFoundWords)
    if (this.setTestStatus) {
      this.setTestStatus(this.textTosetTestStatus)
    }
    return test;
  };

  assertArraysNotEmpty() {
    if (this.wordPoolArr.length === 0) {
      throw new Error("Word pool or weighted patterns array is empty");
    }
  }

  populateOutputWordList() {
    console.log("populateOutputWordList numWordsInTest = " + this.numWordsInTest)
    this.assertArraysNotEmpty();
    //Remove words that were outputted in recent tests from the wordpool
    console.log(`Removing words ${this.previouslyOutputtedWords.flat()} from the word pool`)
    //TODO I shouldn't remove words when the test is created because of user interaction
    //This should only happen when a test is finished
    if (this.removeOutputsFromPool) {
      this.wordPoolArr = this.wordPoolArr.filter(
        word => !this.previouslyOutputtedWords.flat().includes(word)
      );
    }
    let counter = 0;
    while (this.outputWordsList.length < this.numWordsInTest) {
      if (counter === (this.numWordsInTest - this.desiredRandomWords)) {
        //The reason I arbitrarily decided that "random" should take the unwanted patterns
        //into account, but not the wanted patterns, is because my personal use of the
        //unwanted patterns, is that my hand sometimes hurts when typing 't', 'r', 'b'
        //so I really really don't want them, not at some percentage
        console.log("counter = " + counter + " this.desiredRandomWords = " + this.desiredRandomWords);
        this.weightedPatternsArr = this.unwantedWeightedPatternsArr;
      }
      //This is the important part of the function
      this.addNewWordToOutputList();
      counter += 1;
    }

    let numRandomWords;
    if (this.wantedPatternsArr.length === 0 && this.VIPwantedPatterns.length === 0) {
      numRandomWords = this.numWordsInTest;
    }
    else {
      numRandomWords = this.desiredRandomWords;
    }
    let numPatternWords = this.numWordsInTest - numRandomWords;

    this.textTosetTestStatus =
      `${this.numWordsInTest} words were generated. ` +
      `${numRandomWords} random word${numRandomWords === 1 ? '' : 's'}.` +
      ` ${numPatternWords} word${numPatternWords === 1 ? ' is' : 's are'} based on the patterns.` +
      ` ${this.wantedPatternsArr.length + this.VIPwantedPatterns.length} wanted pattern${this.wantedPatternsArr.length + this.VIPwantedPatterns.length === 1 ? '' : 's'} and ${this.unwantedPatternsArr.length} unwanted pattern${this.unwantedPatternsArr.length === 1 ? '' : 's'} were used.`;

    //shuffle the outputWordsList before returning
    this.outputWordsList.sort(() => Math.random() - 0.5);
    console.log(JSON.parse(JSON.stringify(this.outputWordsList)));

    //Get rid of the test words for a while so we don't get the same words in the next few tests
    this.previouslyOutputtedWords.unshift([...this.outputWordsList]);
    //Put words from old test back into the word pool
    //The most important thing is to get the same words you had in the beginning
    //before their weaknesses drops from history
    console.log("previouslyOutputtedWords.length = ", this.previouslyOutputtedWords.length)
    //console.log("desiredHistory = ", this.desiredHistory)
    if (!this.removeOutputsFromPool) {
      //completely reset the word pool and the previously outputted words
      this.wordPoolArr = this.wordPoolArr.concat(this.previouslyOutputtedWords.flat());
      this.previouslyOutputtedWords = [];
    }
    else if (this.previouslyOutputtedWords.length >= this.desiredWordsHistory) {
      const oldestOutputWordsList = [...this.previouslyOutputtedWords.pop()];
      console.log("Putting words back into pool: " + oldestOutputWordsList)
      this.wordPoolArr = this.wordPoolArr.concat([...oldestOutputWordsList]);
    }
  }

  addNewWordToOutputList() {
    console.log("addNewWordToOutputList")
    this.recalculatePatternsWeight();
    let newWord = "";
    newWord = this.findNewBestWordToAddToList();
    this.incrementPatternsCounters(newWord);
    this.outputWordsList.push(newWord);
    //Remove the word from the word pool so it's not the only word that shows up
    this.removeWordFromInputList(newWord);
  }

  incrementPatternsCounters(newWord) {
    for (const pattern of this.weightedPatternsArr) {
      if (newWord.includes(pattern.getPattern())) {
        pattern.incrementCounter();
      }
    }
  }

  recalculatePatternsWeight() {
    let totalCount = 0;
    for (const pattern of this.weightedPatternsArr) {
      totalCount += pattern.getCounterValue();
    }
    console.log(this.constructor.name, ": calculatePatternWeight : totalCount = ", totalCount);
    for (const pattern of this.weightedPatternsArr) {
      pattern.calculateWeight(totalCount);
    }
    //console.log(JSON.parse(JSON.stringify(this.weightedPatternsArr)));
  }

  findNewBestWordToAddToList() {
    console.log(this.constructor.name, "Finding best word");
    let highestScoreWordLength = Infinity; // Initialize to Infinity
    let highestScore = 0;
    let lowestScore = 0;
    let currWordLength = 0;
    let currScore = 0;
    let wordToReturn = "";

    for (const word of this.wordPoolArr) {
      currWordLength = word.length;
      if (currWordLength > this.maxWordLength) {
        currScore = 0;
      } else {
        currScore = this.getWordScore(word);
      }
      if (
        currScore > highestScore ||
        (
          (currScore === highestScore) && (currWordLength < highestScoreWordLength) && (highestScore !== 0)
        )
      ) {
        highestScore = currScore;
        highestScoreWordLength = currWordLength;
        wordToReturn = word;
      }
      if (currScore < lowestScore) {
        lowestScore = currScore;
      }
    }

    if (highestScore === 0) {
      const wordsWithZeroScore = this.wordPoolArr.filter(word => this.getWordScore(word) === 0);
      if (wordsWithZeroScore.length > 0) {
        const randomIndex = Math.floor(Math.random() * wordsWithZeroScore.length);
        wordToReturn = wordsWithZeroScore[randomIndex];
      }
    }

    //This should really only happen if the user did something terrible 
    //with the "exclude" patterns. I could still return a random word
    //but for now I prefer this solution
    if (wordToReturn === "") {
      this.successfullyFoundWords = false;
      return "BadParams";
    }
    console.log(this.constructor.name, "Word to return: ", wordToReturn, "Score: ", highestScore);
    return wordToReturn;
  }

  getWordScore(word) {
    let wordScore = 0;
    for (const pattern of this.weightedPatternsArr) {
      //Turn the string to a regex object
      const patternRegex = new RegExp(pattern.getPattern(), 'g');
      //Check how many times the pattern appears in the word
      const occurrences = (word.match(patternRegex) || []).length;
      //Add the weight of the pattern to the score 
      wordScore += pattern.getWeight() * occurrences;
    }
    // //console.log(this.constructor.name, "Word: ", word, "Score: ", wordScore);
    //TODO trying to normalize the weight of the word by its length
    //This is terrible because there's no chance for getting long words
    // wordScore =  wordScore / word.length ;
    // //console.log(this.constructor.name, "Word: ", word, "Score: ", wordScore);
    return wordScore;
  }

  removeWordFromInputList(newWord) {
    console.log(this.constructor.name, "Removing word '", newWord, "' from wordPoolArr");
    const index = this.wordPoolArr.indexOf(newWord);
    if (index !== -1) {
      this.wordPoolArr.splice(index, 1);
    }
  }

}



class WeightedPattern {
  constructor() {
    this.pattern = "";
    this.counter = 0;
    this.weight = 0.0;
    this.isUnwatedPattern = false;
    this.customPositiveWeight = 0;
  }

  setIsUnwatedPattern(isUnwatedPattern) {
    this.isUnwatedPattern = isUnwatedPattern;
  }

  setCustomPositiveWeight(customPositiveWeight) {
    this.customPositiveWeight = customPositiveWeight;
  }

  setPattern(pattern) {
    this.pattern = pattern;
    //TODO I want to work with regexes
    // this.pattern = new RegExp(pattern);
  }

  getPattern() {
    return this.pattern;
  }

  incrementCounter() {
    this.counter += 1;
  }

  getCounterValue() {
    return this.counter;
  }

  calculateWeight(aggregatedCounters) {
    if (this.isUnwatedPattern) {
      if (aggregatedCounters !== 0) {
        this.weight = -20 - this.counter / aggregatedCounters;
      } else {
        this.weight = -20;
      }
    }
    else {
      if (aggregatedCounters !== 0) {
        this.weight = 1.001 - (this.counter / aggregatedCounters);
      } else {
        this.weight = 1;
      }
      this.weight += this.customPositiveWeight;
    }
  }

  getWeight() {
    return this.weight;
  }
}

export { SingleTestAnalysis, Metadata, DesiredWeaknessPatterns, HistoryManager }