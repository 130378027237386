import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';

const AdComponent = ({
  position = 'bottom',
  adClient = 'ca-pub-7326371163212528',
  adSlot = '9630327136',
  adFormat = 'auto',
  fullWidthResponsive = true
}) => {
  const [adStatus, setAdStatus] = React.useState('loading');
  const [debugInfo, setDebugInfo] = React.useState('');

  const isVertical = ['left', 'right'].includes(position);
  const defaultDimensions = isVertical
    ? { width: '120px', height: '600px' }
    : { width: '728px', height: '90px' };

  useEffect(() => {
    const loadAd = () => {
      try {
        if (window.adsbygoogle) {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
          setAdStatus('loaded');
          setDebugInfo('Ad push initiated');
        } else {
          setAdStatus('not-loaded');
          setDebugInfo('adsbygoogle not available');
        }
      } catch (e) {
        console.error('Error loading ad:', e);
        setAdStatus('error');
        setDebugInfo(`Error: ${e.message}`);
      }
    };

    const timeoutId = setTimeout(loadAd, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  const getPositionStyle = () => {
    switch (position) {
      case 'left':
        return { left: 0, top: '50%', transform: 'translateY(-50%)' };
      case 'right':
        return { right: 0, top: '50%', transform: 'translateY(-50%)' };
      case 'top':
        return { top: 0, left: '50%', transform: 'translateX(-50%)' };
      case 'bottom':
      default:
        return { bottom: 0, left: '50%', transform: 'translateX(-50%)' };
    }
  };

  return (
    <Box
      className="ad-container"
      id="ad-box"
      sx={{
        position: 'fixed',
        ...defaultDimensions,
        ...getPositionStyle(),
        zIndex: 1000,
        backgroundColor: 'background.paper',
        border: '1px solid #ccc',
      }}
    >
      {adStatus === 'loaded' ? (
        <ins
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client={adClient}
          data-ad-slot={adSlot}
          data-ad-format={adFormat}
          data-full-width-responsive={fullWidthResponsive}
        />
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
          <Typography variant="body1">
            {adStatus === 'loading' ? 'Loading Ad...' : 'Ad Placement'}
          </Typography>
          {/* <Typography variant="caption" style={{ color: 'gray' }}>
            {debugInfo}
          </Typography> */}
        </Box>
      )}
    </Box>
  );
};

export { AdComponent };