import React from 'react';
import { Typography, Box, Link, Stack } from '@mui/material';


const friends = [
  { name: 'Rhig', link: 'www.google.com' },
  { name: 'Guti', link: 'www.google.com' },
  { name: 'Paramewomewo', link: 'www.google.com' },
  { name: 'Reddit Typing Community', link: 'www.reddit.com/r/Typing' },
]

const openSourceLibraries = [
  { name: 'React', link: 'https://reactjs.org/' },
  { name: 'Material-UI', link: 'https://mui.com/' },
  { name: 'Lodash', link: 'https://lodash.com/' },
  { name: 'Helmet', link: 'https://helmetjs.github.io/' },
  { name: 'reactour', link: 'https://reactour.js.org/' },
  { name: 'html2canvas', link: 'https://html2canvas.hertzen.com/' },
]



const Contributors = () => {
  return (
    <Stack spacing={4}>
    <Box>
      <Typography variant="h4">
        Friends who helped
      </Typography>
      {friends.map((contributor, index) => (
        <Box key={index}>
          <Link href={contributor.link} color="textPrimary">
            {contributor.name}
          </Link>
        </Box>
      ))}
    </Box>
    <Box>
      <Typography variant="h4">
        Open Source Libraries Used
      </Typography>
      {openSourceLibraries.map((contributor, index) => (
        <Box key={index}>
          <Link href={contributor.link} color="textPrimary">
            {contributor.name}
          </Link>
        </Box>
      ))}
    </Box>
    </Stack>
  );
}

export default Contributors;