import React from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Typography } from '@mui/material';

import { TypingTestOptions } from './TypingTestOptions';
import { TypingTestResults } from './TypingTestResults';

function AboveTest({ setFocusMode }) {
  const toggleFocusMode = (isOn) => {
    setFocusMode(isOn);
  };

  return (
    <>
      <Grid
        container
        data-tour="typing-test-options"
        alignItems="center"
        spacing={2}
      >
        <Grid xs={12} md={6} display="flex" justifyContent="center">
          <TypingTestResults onToggleSwitch={toggleFocusMode} />
        </Grid>
        <Grid xs={12} md={6} display="flex" justifyContent="center">
          <TypingTestOptions />
        </Grid>
      </Grid>
    </>
  );
}

export { AboveTest };